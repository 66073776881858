import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { parseISO, format } from "date-fns";
import { getFirestore, doc, getDoc, updateDoc, setDoc, increment } from "firebase/firestore";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Stack,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  CardActions,
  Checkbox,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Slider
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AuthContext } from "auth";
import jobsStore from "../stores/jobsStore";
import SoftBox from "components/SoftBox";
import GeopoliticalVisualization from "../GeopoliticalVisualization";
import ESGVisualization from "../ESGVisualization";
import StockChart from "layouts/GraphVisualization";
import ReactToPrint from "react-to-print";
import { marked } from "marked";
import { countryNames } from "layouts/CountryMap";

const theme = createTheme({
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          "& .markdown-content": {
            fontSize: "10px", // Set the font size smaller
          },
        },
      },
    },
  },
});

const printStyles = `
  @page {
    margin: 20mm 10mm;
  }
  header {
    text-align: right;
  }
  body {
    font-size: 12pt;
  }
  @media print {
    /* Hide elements on the print */
    .no-print {
      display: none;
    }
    /* Remove default footers */
    footer {
      display: none;
    }
  }
`;

const ReportContent = React.forwardRef(({ reportMarkdown }, ref) => {
  const reportLines = reportMarkdown.split("\n");

  // Extracting title and investment type using their index in the Markdown content
  const title = reportLines[0].replace("# ", "");
  const investmentType = reportLines[1].replace("## ", "");
  const timeGenerated = reportLines[2].replace("## ", "");

  // Locate where the word "Geopolitical" starts in the Markdown
  const geopoliticalStartIndex = reportMarkdown.indexOf("### Geopolitical");

  // If "Geopolitical" is not found, use the fourth line as fallback
  const fallbackStartIndex = reportLines[3]
    ? reportMarkdown.indexOf(reportLines[4])
    : -1;

  // Use geopoliticalStartIndex if found; otherwise, use fallbackStartIndex
  const contentStartIndex =
    geopoliticalStartIndex !== -1 ? geopoliticalStartIndex : fallbackStartIndex;

  // Remove everything before "Geopolitical" and get the rest of the content
  const restOfContentMarkdown = reportMarkdown.substring(contentStartIndex);
  const restOfContentHTML = marked(restOfContentMarkdown);

  return (
    <div ref={ref}>
      <ThemeProvider theme={theme}>
        <Card style={{ p: 2 }}>
          <CardContent>
            {/* Render the title */}
            <Typography variant="h4" component="h1" gutterBottom>
              {title}
            </Typography>
            {/* Render the investment type */}
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              style={{ marginBottom: "20px" }}
            >
              {investmentType}
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              style={{ marginBottom: "20px" }}
            >
              {timeGenerated}
            </Typography>
            {/* Render the rest of the report content from "Geopolitical" */}
            <div dangerouslySetInnerHTML={{ __html: restOfContentHTML }} />
          </CardContent>
        </Card>
      </ThemeProvider>
    </div>
  );
});

const ReportCard = ({ reportMarkdown }) => {
  const componentRef = useRef();

  return (
    <>
      <ReportContent reportMarkdown={reportMarkdown} ref={componentRef} />
      <CardActions>
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              style={{ backgroundColor: "#5271ff", color: "white" }}
            >
              Export to PDF
            </Button>
          )}
          content={() => componentRef.current}
          pageStyle={printStyles}
        />
      </CardActions>
    </>
  );
};

const getInvestmentTypeColor = (type) => {
  switch (type) {
    case 'equities':
      return '#00bcd4'; // cyan
    case 'commodities':
      return '#ff9800'; // orange
    case 'region':
      return '#4caf50'; // green
    case 'country':
      return '#9c27b0'; // purple
    default:
      return '#607d8b'; // default color
  }
};

const Badge = styled(Box)(({ color }) => ({
  display: 'inline-block',
  padding: '2px 4px', // Reduced padding
  borderRadius: '4px',
  backgroundColor: color,
  color: 'white',
  fontWeight: 'bold',
  fontSize: '1rem', // Smaller font size
  marginLeft: '12px', // Add spacing between badges
}));

const JobDetails = observer(() => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [job, setJob] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const [geoTabValue, setGeoTabValue] = useState(1);
  const [esgTabValue, setEsgTabValue] = useState(0);
  const [emailnotifs, setEmailNotifs] = useState(false);
  const [jobFrequency, setJobFrequency] = useState("week");
  const tabs = [
    { label: "Geopolitical Events", value: 1 },
    { label: "Global Visualization", value: 0 },
    ...(job?.input?.type === 'equities' ? [{ label: "Historical Impact", value: 2 }] : []),
  ];


  const [selectedTab, setSelectedTab] = useState(0);
  const [showNewCard, setShowNewCard] = useState(false); // State to manage the visibility of the new card
  const [recurringJob, setRecurringJob] = useState(false);

  const [zoom, setZoom] = useState(1);

  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  const [expiryDate, setExpiryDate] = useState("week");

  const handleExpiryDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  const [isRecurringJob, setIsRecurringJob] = useState(false);


  useEffect(() => {
    const fetchJob = async () => {
      setIsLoading(true);
      const db = getFirestore();
      const jobRef = doc(db, "jobs", jobId);
      const docSnap = await getDoc(jobRef);
  
      if (docSnap.exists()) {
        const jobData = docSnap.data();
  
        // Transform the scores in gsiMapping
        const transformedGsiMapping = Object.entries(jobData.result.gsiMapping).reduce((acc, [country, { score, statement }]) => {
          acc[country] = { score: 2 * score - 1, statement };
          return acc;
        }, {});
  
        // Update jobData with transformed gsiMapping scores
        jobData.result.gsiMapping = transformedGsiMapping;
  
        setJob({ id: docSnap.id, ...jobData });
      } else {
        console.log("No such document!");
      }
  
      const recurringJobRef = doc(db, 'recurring_jobs', jobId);
      const recurringDocSnap = await getDoc(recurringJobRef);
  
      if (recurringDocSnap.exists()) {
        setIsRecurringJob(true);
      } else {
        setIsRecurringJob(false);
      }
  
      setIsLoading(false);
    };
  
    fetchJob();
  }, [jobId]);
  

  const handleGeoTabChange = (event, newValue) => {
    setGeoTabValue(newValue);
  };

  const handleEmailNotifsChange = (event) => {
    setEmailNotifs(event.target.checked);
  };

  const handleRunComparativeAnalysis = () => {
    setShowNewCard(!showNewCard); // Toggle the visibility of the new card
  };

  // Function to update job frequency
  const updateJobFrequency = async (jobId, frequency) => {
    const db = getFirestore();
    const jobRef = doc(db, "jobs", jobId);

    try {
      await updateDoc(jobRef, { frequency });
      console.log("Job frequency updated successfully");
    } catch (error) {
      console.error("Error updating job frequency:", error);
    }
  };

  const handleJobFrequencyChange = (event) => {
    const newFrequency = event.target.value;
    setJobFrequency(newFrequency);

    // Update the frequency in Firestore
    updateJobFrequency(jobId, newFrequency);
  };

  const handleRerunAnalysis = async () => {
    setIsLoading(true); // Optionally set loading state

    const formData = new FormData();
    formData.append("userId", currentUser.uid);
    formData.append("name", job.input.name);
    formData.append("type", job.input.type);
    formData.append("specificType", job.input.specific_investment);
    formData.append("is_gen_report", job.input.is_gen_report ? 'true' : 'false');
    formData.append("is_geopolitical", 'true'); // Explicitly set is_geopolitical to true
    formData.append("submissionTime", new Date().toISOString()); // Use current time for new submission
    formData.append("jobId", jobId);

    try {
      const response = await fetch(`https://algosight-app.com/run-analysis`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Rerun successful", data);

        // Update the job state with the new results
        setJob((prevJob) => ({
          ...prevJob,
          result: data.results,
          lastRun: new Date().toISOString(), // Update the last run time
        }));

        // Increment the assetsAnalyzed metric
        const db = getFirestore();
        const userMetricsRef = doc(db, 'userMetrics', currentUser.uid);

        const userMetricsDoc = await getDoc(userMetricsRef);
        if (!userMetricsDoc.exists()) {
          // Initialize the document if it doesn't exist
          await setDoc(userMetricsRef, {
            logins: 0,
            analysisJobsCreated: {
              equities: 0,
              commodities: 0,
              regions: 0,
              countries: 0
            },
            industrySelections: {
              Technology: 0,
              Healthcare: 0,
              Finance: 0,
              Energy: 0,
              ConsumerGoods: 0
            },
            industriesAnalysed: {
              Technology: 0,
              Healthcare: 0,
              Finance: 0,
              Energy: 0,
              ConsumerGoods: 0
            },
            assetsAnalyzed: {
              equities: 0,
              commodities: 0,
              regions: 0,
              countries: 0
            }
          });
        }

        await updateDoc(userMetricsRef, {
          [`assetsAnalyzed.${job.input.type}`]: increment(1)
        });

        console.log('Updated assets analyzed metrics');
      } else {
        throw new Error("Failed to rerun analysis");
      }
    } catch (error) {
      console.error("Error rerunning analysis:", error);
      alert("An error occurred while rerunning the analysis.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      </DashboardLayout>
    );
  }

  const handleDeleteJob = async () => {
    if (currentUser && currentUser.uid && jobId) {
      setIsDeleting(true); // Start the deletion process
      await jobsStore.deleteJob(currentUser.uid, jobId);
      navigate("/dashboard");

      setIsDeleting(false); // End the deletion process
    }
  };

  if (!job) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Box p={3}>
          <Typography>Job not found</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  if (isDeleting) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
          <Typography variant="h6" marginTop={2}>
            Deleting Job...
          </Typography>
        </Box>
      </DashboardLayout>
    );
  }

  // Define a function that returns a color based on the score
  const getScoreColor = (score) => {
    let red, green, blue, alpha;

    if (score > 0) {
      red = 0;
      green = 180; // Slightly brighter green
      blue = 0;
      alpha = score - score * 0.4;
    } else if (score < 0) {
      red = 245;
      green = 0;
      blue = 0;
      alpha = Math.abs(score) + score * 0.4;
    } else {
      red = 200;
      green = 200;
      blue = 200;
      alpha = 1;
    }

    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  };


  // Modify the ScoreBox styled component to use pastel colors and horizontal layout
  const ScoreBox = styled(Box)(({ score }) => ({
    background: getScoreColor(score),
    color: "black",
    padding: "4px 8px",
    borderRadius: "4px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "60px", // Adjust width as needed
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    transition: "background-color 0.3s",
    marginLeft: "8px", // Space from the label
  }));

  const StyledCard = styled(Card)({
    marginTop: "16px", // Match grid spacing or adjust as needed
    padding: "20px", // Optional: if you want inner padding in all cards
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: "bold",
    // Set a width or max width if you want to control the size of the cells
    width: "50%",
  }));

  // Rendering function for the report
  const renderReportCard = () => {
    if (job && job.result.report) {
      return <ReportCard reportMarkdown={job.result.report} />;
    } else {
      return (
        <>
        </>
        // <Typography variant="body2">No report has been generated.</Typography>
      );
    }
  };

  const renderGeopoliticalVisualization = () => {
    if (
      job &&
      job.result.gsiMapping &&
      Object.keys(job.result.gsiMapping).length > 0
    ) {
      const mappingData = Object.entries(job.result.gsiMapping).map(
        ([country, { score, statement }]) => ({
          country: country.toUpperCase(), // Ensure country codes are in correct format
          value: `${statement} (Score: ${score.toFixed(2)})`,
          color: getScoreColor(score) // Add color based on score
        })
      );

      return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ transform: `scale(${zoom})`, transformOrigin: '50% 50%' }}>
            <GeopoliticalVisualization mappingData={mappingData} />
          </div>
          <Slider
            value={zoom}
            min={0.5}
            max={2}
            step={0.1}
            onChange={handleZoomChange}
            aria-labelledby="zoom-slider"
            style={{ marginTop: '16px', width: '80%' }}
            defaultValue={2}

          />
        </div>
      );
    } else {
      return (
        <Typography variant="body1">
          No geopolitical analysis has been run.
        </Typography>
      );
    }
  };



  // const renderCountryTable = () => (
  //   <>
  //     <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2}>

  //       <Typography variant="h6" gutterBottom>Country Geopolitical Overview</Typography>
  //       <TableContainer component={Paper} style={{ maxHeight: 400, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
  //         <Table stickyHeader size="small">
  //           <TableBody>
  //             {Object.entries(job.result.gsiMapping).map(([country, { score, statement }]) => (
  //               <TableRow key={country}>
  //                 <TableCell>{country}</TableCell>
  //                 <TableCell>
  //                   <Box display="flex" alignItems="center">
  //                     <Box
  //                       style={{
  //                         background: getScoreColor(score),
  //                         color: 'black',
  //                         padding: '4px 8px',
  //                         borderRadius: '4px',
  //                         display: 'inline-flex',
  //                         alignItems: 'center',
  //                         justifyContent: 'center',
  //                         minWidth: '60px',
  //                         boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  //                         transition: 'background-color 0.3s',
  //                         marginLeft: '8px',
  //                       }}
  //                     >
  //                       <Typography variant="body2">{score.toFixed(2)}</Typography>
  //                     </Box>
  //                   </Box>
  //                 </TableCell>
  //                 <TableCell>{statement}</TableCell>
  //               </TableRow>
  //             ))}
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </Box>
  //   </>
  // );


  const renderCountryTable = () => (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
        <Typography variant="h6" gutterBottom>Country Geopolitical Overview</Typography>
        <TableContainer component={Paper} style={{ maxHeight: 400, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
          <Table stickyHeader size="small">
            <TableBody>
              {Object.entries(job.result.gsiMapping).map(([countryCode, { score, statement }]) => (
                <TableRow key={countryCode}>
                  <TableCell>{countryNames[countryCode] || countryCode}</TableCell>
                  <TableCell>
                    <ScoreBox score={score}>{score.toFixed(2)}</ScoreBox>
                  </TableCell>
                  <TableCell>{statement}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );

  const renderGeopoliticalTopicsTable = () => (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
        <Typography variant="h6" gutterBottom>Geopolitical Analysis Overview</Typography>
        <TableContainer component={Paper} style={{ maxHeight: 400, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
          <Table stickyHeader size="small">
            <TableBody>
              {Object.entries(job.result.geopoliticalTopics).map(([topic, [score, description]]) => (
                description && (
                  <TableRow key={topic}>
                    <TableCell>{topic}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box
                          style={{
                            background: getScoreColor(score),
                            color: 'black',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '60px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            transition: 'background-color 0.3s',
                            marginLeft: '8px',
                          }}
                        >
                          <Typography variant="body2">{score.toFixed(2)}</Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>{description}</TableCell>
                  </TableRow>
                )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );



  const renderNewsSources = () => (
    <>
      <Typography variant="h6" gutterBottom>News Sources</Typography>
      <Paper style={{ padding: '16px', marginTop: '16px' }}>
        <List>
          {job.result.newsSources.map((source, index) => (
            <ListItem key={index} divider>
              <ListItemText
                primary={source}
                primaryTypographyProps={{
                  fontSize: '1rem', // Smaller font size
                  // color: '#4285f4', // Default blue color
                  // fontWeight: 'bold' // Bold text
                }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );

  const handleSetRecurringJob = async () => {
    try {
      const response = await fetch('http://127.0.0.1:4000/create_recurring_job', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          userId: currentUser.uid,
          frequency: jobFrequency,
          expiryDate: expiryDate, // Add expiry date to the API call
          jobId: jobId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Recurring job created:', data);
      } else {
        throw new Error('Failed to create recurring job');
      }
    } catch (error) {
      console.error('Error creating recurring job:', error);
      alert('An error occurred while creating the recurring job.');
    }
  };

  const isConfigurationValid = () => {
    if (jobFrequency === 'day') {
      return true;
    }
    if (jobFrequency === 'week') {
      return true;
    }
    if (jobFrequency === 'month' && (expiryDate === 'month')) {
      return true;
    }
    return false;
  };


  const handleDeleteRecurringJob = async () => {
    try {
      const response = await fetch(`http://127.0.0.1:4000/delete_recurring_job`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          jobId: jobId,
        }),
      });

      if (response.ok) {
        setIsRecurringJob(false);
        console.log('Recurring job deleted.');
      } else {
        throw new Error('Failed to delete recurring job');
      }
    } catch (error) {
      console.error('Error deleting recurring job:', error);
      alert('An error occurred while deleting the recurring job.');
    }
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Typography variant="h4">
                    {job.input?.name || "Job Details"}
                  </Typography>
                  {job.input?.specific_investment && (
                    <Badge color="#5271ff">
                      {job.input.specific_investment}
                    </Badge>
                  )}
                  {job.input?.type && (
                    <Badge color={getInvestmentTypeColor(job.input.type)}>
                      {job.input.type}
                    </Badge>
                  )}
                </Box>
                <Box mt={3}>
                  <Typography variant="body2" color="textSecondary">
                    Last Run At:{" "}
                    {job.lastRun ? format(parseISO(job.lastRun), "PPPpp") : "N/A"}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={2} mt={2}>
                  <Button
                    variant="contained"
                    onClick={handleRerunAnalysis}
                    style={{ backgroundColor: "#5271ff", color: "white" }}
                  >
                    Rerun Analysis
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDeleteJob}
                    style={{ color: "white" }}
                  >
                    Delete Job
                  </Button>
                </Stack>
                <Box mt={3}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={emailnotifs}
                          onChange={handleEmailNotifsChange}
                        />
                      }
                      label="Receive E-mail Notifications"
                    />
                  </FormControl>
                </Box>
                {/* <Box mt={3}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recurringJob}
                          onChange={() => setRecurringJob(!recurringJob)}
                        />
                      }
                      label="Run a Scheduled Analysis"
                    />
                    <>

                      {recurringJob && (
                        <Box display="flex" alignItems="center" mt={2}>
                          <Typography variant="h6" gutterBottom>
                            Frequency:
                          </Typography>
                          <Select
                            value={jobFrequency}
                            onChange={handleJobFrequencyChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{ marginLeft: 16 }}
                          >
                            <MenuItem value="day">Every Day</MenuItem>
                            <MenuItem value="week">Every Week</MenuItem>
                            <MenuItem value="month">Every Month</MenuItem>
                          </Select>
                          <Typography variant="h6" gutterBottom style={{ marginLeft: 16 }}>
                            Expiry:
                          </Typography>
                          <Select
                            value={expiryDate}
                            onChange={handleExpiryDateChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{ marginLeft: 16 }}
                          >
                            <MenuItem value="week">1 Week</MenuItem>
                            <MenuItem value="month">1 Month</MenuItem>
                          </Select>
                          <Button
                            variant="contained"
                            onClick={handleSetRecurringJob}
                            style={{ backgroundColor: "#5271ff", color: "white", marginLeft: 16, width: 450 }}
                            disabled={!isConfigurationValid()}
                          >
                            Schedule Analysis Run
                          </Button>
                        </Box>
                      )}

                    </>
                    {isRecurringJob && (
                      <Box mt={2}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleDeleteRecurringJob}
                          style={{ color: 'white' }}
                        >
                          Delete Recurring Job
                        </Button>
                      </Box>
                    )}
                  </FormControl>
                </Box> */}

              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6" gutterBottom>
                      Geopolitical Pulse
                    </Typography>
                    <Tooltip title="Geopolitical Pulse provides a news sentiment score reflecting the stability and geopolitical risk associated with a country or region. Key: -1: negative, +1: positive">
                      <IconButton size="small">
                        <InfoOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Tooltip title="Feature Coming Soon" arrow>
                    <span>
                      <Button
                        variant="contained"
                        onClick={handleRunComparativeAnalysis}
                        style={{ backgroundColor: "#5271ff", color: "white" }}
                        disabled
                      >
                        {showNewCard ? "Hide Comparative Analysis" : "Show Comparative Analysis"}
                      </Button>
                    </span>
                  </Tooltip>
                </Box>

                {job.result?.gsiOverall !== undefined ? (
                  <>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Typography variant="subtitle2" gutterBottom>
                        Score:
                      </Typography>
                      <ScoreBox score={job.result.gsiOverall}>
                        <Typography variant="body2">{job.result.gsiOverall.toFixed(2)}</Typography>
                      </ScoreBox>
                    </Box>
                    <Tabs value={geoTabValue} onChange={handleGeoTabChange} aria-label="Geopolitical analysis tabs">
                      {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} value={tab.value} />
                      ))}
                    </Tabs>
                    {geoTabValue === 0 && (
                      <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {renderGeopoliticalVisualization()}
                      </Grid>
                    )}
                    {geoTabValue === 1 && (
                      <>
                        {renderGeopoliticalTopicsTable()}
                        {renderCountryTable()}
                        {renderNewsSources()}
                      </>
                    )}
                    {geoTabValue === 2 && job.input?.type === 'equities' && job.result && job.result.gsiLabels && (
                      <StockChart
                        showCompAnalysis={showNewCard}
                        specific_investment={job.input.specific_investment}
                        labels={job.result.gsiLabels}
                        labelData={job.result.gsiData}
                        labelSummary={job.result.gsiSummaries}
                      />
                    )}
                  </>
                ) : (
                  <Typography variant="body1">No geopolitical analysis has been run.</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>{renderReportCard()}</Grid>
        </Grid >
      </SoftBox >
    </DashboardLayout >
  );
});

export default JobDetails;




// import React, { useContext, useState, useEffect, useRef } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { observer } from "mobx-react";
// import { parseISO, format } from "date-fns";
// import { getFirestore, doc, getDoc, updateDoc, setDoc, increment } from "firebase/firestore";
// import {
//   Button,
//   Card,
//   CardContent,
//   Typography,
//   Box,
//   Grid,
//   CircularProgress,
//   Stack,
//   Tooltip,
//   IconButton,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
//   Paper,
//   FormControl,
//   FormControlLabel,
//   RadioGroup,
//   Radio,
//   Tabs,
//   Tab,
//   CardActions,
//   Checkbox,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import { AuthContext } from "auth";
// import jobsStore from "../stores/jobsStore";
// import SoftBox from "components/SoftBox";
// import GeopoliticalVisualization from "../GeopoliticalVisualization";
// import ESGVisualization from "../ESGVisualization";
// import StockChart from "layouts/GraphVisualization";
// import ReactToPrint from "react-to-print";
// import { marked } from "marked";

// const theme = createTheme({
//   components: {
//     MuiCardContent: {
//       styleOverrides: {
//         root: {
//           "& .markdown-content": {
//             fontSize: "10px", // Set the font size smaller
//           },
//         },
//       },
//     },
//   },
// });

// const printStyles = `
//   @page {
//     margin: 20mm 10mm;
//   }
//   header {
//     text-align: right;
//   }
//   body {
//     font-size: 12pt;
//   }
//   @media print {
//     /* Hide elements on the print */
//     .no-print {
//       display: none;
//     }
//     /* Remove default footers */
//     footer {
//       display: none;
//     }
//   }
// `;

// const ReportContent = React.forwardRef(({ reportMarkdown }, ref) => {
//   const reportLines = reportMarkdown.split("\n");

//   // Extracting title and investment type using their index in the Markdown content
//   const title = reportLines[0].replace("# ", "");
//   const investmentType = reportLines[1].replace("## ", "");
//   const timeGenerated = reportLines[2].replace("## ", "");

//   // Locate where the word "Geopolitical" starts in the Markdown
//   const geopoliticalStartIndex = reportMarkdown.indexOf("### Geopolitical");

//   // If "Geopolitical" is not found, use the fourth line as fallback
//   const fallbackStartIndex = reportLines[3]
//     ? reportMarkdown.indexOf(reportLines[4])
//     : -1;

//   // Use geopoliticalStartIndex if found; otherwise, use fallbackStartIndex
//   const contentStartIndex =
//     geopoliticalStartIndex !== -1 ? geopoliticalStartIndex : fallbackStartIndex;

//   // Remove everything before "Geopolitical" and get the rest of the content
//   const restOfContentMarkdown = reportMarkdown.substring(contentStartIndex);
//   const restOfContentHTML = marked(restOfContentMarkdown);

//   return (
//     <div ref={ref}>
//       <ThemeProvider theme={theme}>
//         <Card style={{ p: 2 }}>
//           <CardContent>
//             {/* Render the title */}
//             <Typography variant="h4" component="h1" gutterBottom>
//               {title}
//             </Typography>
//             {/* Render the investment type */}
//             <Typography
//               variant="h6"
//               component="h2"
//               gutterBottom
//               style={{ marginBottom: "20px" }}
//             >
//               {investmentType}
//             </Typography>
//             <Typography
//               variant="h6"
//               component="h2"
//               gutterBottom
//               style={{ marginBottom: "20px" }}
//             >
//               {timeGenerated}
//             </Typography>
//             {/* Render the rest of the report content from "Geopolitical" */}
//             <div dangerouslySetInnerHTML={{ __html: restOfContentHTML }} />
//           </CardContent>
//         </Card>
//       </ThemeProvider>
//     </div>
//   );
// });

// const ReportCard = ({ reportMarkdown }) => {
//   const componentRef = useRef();

//   return (
//     <>
//       <ReportContent reportMarkdown={reportMarkdown} ref={componentRef} />
//       <CardActions>
//         <ReactToPrint
//           trigger={() => (
//             <Button
//               variant="contained"
//               style={{ backgroundColor: "#5271ff", color: "white" }}
//             >
//               Export to PDF
//             </Button>
//           )}
//           content={() => componentRef.current}
//           pageStyle={printStyles}
//         />
//       </CardActions>
//     </>
//   );
// };

// const getInvestmentTypeColor = (type) => {
//   switch (type) {
//     case 'equities':
//       return '#00bcd4'; // cyan
//     case 'commodities':
//       return '#ff9800'; // orange
//     case 'region':
//       return '#4caf50'; // green
//     case 'country':
//       return '#9c27b0'; // purple
//     default:
//       return '#607d8b'; // default color
//   }
// };

// const Badge = styled(Box)(({ color }) => ({
//   display: 'inline-block',
//   padding: '2px 4px', // Reduced padding
//   borderRadius: '4px',
//   backgroundColor: color,
//   color: 'white',
//   fontWeight: 'bold',
//   fontSize: '1rem', // Smaller font size
//   marginLeft: '12px', // Add spacing between badges
// }));



// const JobDetails = observer(() => {
//   const { jobId } = useParams();
//   const navigate = useNavigate();
//   const { currentUser } = useContext(AuthContext);
//   const [job, setJob] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isDeleting, setIsDeleting] = useState(false);

//   const [geoTabValue, setGeoTabValue] = useState(0);
//   const [esgTabValue, setEsgTabValue] = useState(0);
//   const [emailnotifs, setEmailNotifs] = useState(false);
//   const [jobFrequency, setJobFrequency] = useState("week");
//   // Add new state to manage dynamic tabs
//   const [tabs, setTabs] = useState(["Visualization", "Events"]);
//   const [selectedTab, setSelectedTab] = useState(0);
//   const [showNewCard, setShowNewCard] = useState(false); // State to manage the visibility of the new card
//   const [recurringJob, setRecurringJob] = useState(false);


//   // Handle changing tabs
//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//   };

//   // We store job data in FireStore and fetch from there
//   useEffect(() => {
//     const fetchJob = async () => {
//       setIsLoading(true);
//       const db = getFirestore();
//       const jobRef = doc(db, "jobs", jobId);
//       const docSnap = await getDoc(jobRef);

//       if (docSnap.exists()) {
//         setJob({ id: docSnap.id, ...docSnap.data() });
//       } else {
//         console.log("No such document!");
//       }
//       setIsLoading(false);
//     };

//     fetchJob();
//   }, [jobId]);

//   const handleGeoTabChange = (event, newValue) => {
//     setGeoTabValue(newValue);
//   };

//   const handleEmailNotifsChange = (event) => {
//     setEmailNotifs(event.target.checked);
//   };


//   const handleRunComparativeAnalysis = () => {
//     setShowNewCard(!showNewCard); // Toggle the visibility of the new card
//   };

//   // Function to update job frequency
//   const updateJobFrequency = async (jobId, frequency) => {
//     const db = getFirestore();
//     const jobRef = doc(db, "jobs", jobId);

//     try {
//       await updateDoc(jobRef, { frequency });
//       console.log("Job frequency updated successfully");
//     } catch (error) {
//       console.error("Error updating job frequency:", error);
//     }
//   };

//   const handleJobFrequencyChange = (event) => {
//     const newFrequency = event.target.value;
//     setJobFrequency(newFrequency);

//     // Update the frequency in Firestore
//     updateJobFrequency(jobId, newFrequency);
//   };

//   // const handleRerunAnalysis = async () => {
//   //   setIsLoading(true); // Optionally set loading state

//   //   const formData = new FormData();
//   //   formData.append("userId", currentUser.uid);
//   //   formData.append("name", job.input.name);
//   //   formData.append("type", job.input.type);
//   //   formData.append("specificType", job.input.specific_investment);
//   //   formData.append("is_gen_report", job.input.is_gen_report ? 'true' : 'false'); //
//   //   formData.append("is_geopolitical", 'true'); // Explicitly set is_geopolitical to true
//   //   formData.append("submissionTime", new Date().toISOString()); // Use current time for new submission
//   //   formData.append("jobId", jobId);


//   //   try {
//   //     const response = await fetch(`http://127.0.0.1:5000/run-analysis`, {
//   //       method: "POST",
//   //       body: formData,
//   //     });

//   //     if (response.ok) {
//   //       const data = await response.json();
//   //       console.log("Rerun successful", data);

//   //       // Update the job state with the new results
//   //       setJob((prevJob) => ({
//   //         ...prevJob,
//   //         result: data.results,
//   //         lastRun: new Date().toISOString(), // Update the last run time
//   //       }));
//   //     } else {
//   //       throw new Error("Failed to rerun analysis");
//   //     }
//   //   } catch (error) {
//   //     console.error("Error rerunning analysis:", error);
//   //     alert("An error occurred while rerunning the analysis.");
//   //   } finally {
//   //     setIsLoading(false);
//   //   }
//   // };

//   const handleRerunAnalysis = async () => {
//     setIsLoading(true); // Optionally set loading state

//     const formData = new FormData();
//     formData.append("userId", currentUser.uid);
//     formData.append("name", job.input.name);
//     formData.append("type", job.input.type);
//     formData.append("specificType", job.input.specific_investment);
//     formData.append("is_gen_report", job.input.is_gen_report ? 'true' : 'false');
//     formData.append("is_geopolitical", 'true'); // Explicitly set is_geopolitical to true
//     formData.append("submissionTime", new Date().toISOString()); // Use current time for new submission
//     formData.append("jobId", jobId);

//     try {
//       const response = await fetch(`http://127.0.0.1:5000/run-analysis`, {
//         method: "POST",
//         body: formData,
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log("Rerun successful", data);

//         // Update the job state with the new results
//         setJob((prevJob) => ({
//           ...prevJob,
//           result: data.results,
//           lastRun: new Date().toISOString(), // Update the last run time
//         }));

//         // Increment the assetsAnalyzed metric
//         const db = getFirestore();
//         const userMetricsRef = doc(db, 'userMetrics', currentUser.uid);

//         const userMetricsDoc = await getDoc(userMetricsRef);
//         if (!userMetricsDoc.exists()) {
//           // Initialize the document if it doesn't exist
//           await setDoc(userMetricsRef, {
//             logins: 0,
//             analysisJobsCreated: {
//               equities: 0,
//               commodities: 0,
//               regions: 0,
//               countries: 0
//             },
//             industrySelections: {
//               Technology: 0,
//               Healthcare: 0,
//               Finance: 0,
//               Energy: 0,
//               ConsumerGoods: 0
//             },
//             industriesAnalysed: {
//               Technology: 0,
//               Healthcare: 0,
//               Finance: 0,
//               Energy: 0,
//               ConsumerGoods: 0
//             },
//             assetsAnalyzed: {
//               equities: 0,
//               commodities: 0,
//               regions: 0,
//               countries: 0
//             }
//           });
//         }

//         await updateDoc(userMetricsRef, {
//           [`assetsAnalyzed.${job.input.type}`]: increment(1)
//         });

//         console.log('Updated assets analyzed metrics');
//       } else {
//         throw new Error("Failed to rerun analysis");
//       }
//     } catch (error) {
//       console.error("Error rerunning analysis:", error);
//       alert("An error occurred while rerunning the analysis.");
//     } finally {
//       setIsLoading(false);
//     }
//   };


//   if (isLoading) {
//     return (
//       <DashboardLayout>
//         <DashboardNavbar />
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//         </Box>
//       </DashboardLayout>
//     );
//   }

//   const handleDeleteJob = async () => {
//     if (currentUser && currentUser.uid && jobId) {
//       setIsDeleting(true); // Start the deletion process
//       await jobsStore.deleteJob(currentUser.uid, jobId);
//       navigate("/dashboard");

//       setIsDeleting(false); // End the deletion process
//     }
//   };

//   if (!job) {
//     return (
//       <DashboardLayout>
//         <DashboardNavbar />
//         <Box p={3}>
//           <Typography>Job not found</Typography>
//         </Box>
//       </DashboardLayout>
//     );
//   }

//   if (isDeleting) {
//     return (
//       <DashboardLayout>
//         <DashboardNavbar />
//         <Box
//           display="flex"
//           flexDirection="column"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//           <Typography variant="h6" marginTop={2}>
//             Deleting Job...
//           </Typography>
//         </Box>
//       </DashboardLayout>
//     );
//   }

//   // Define a function that returns a color based on the score
//   const getScoreColor = (score) => {
//     let red, green, blue, alpha;

//     if (score > 0) {
//       // Bright green as score approaches 1
//       red = 0;
//       green = 255;
//       blue = 0;
//       alpha = score - score * 0.4;
//     } else if (score < 0) {
//       // Bright red as score approaches -1
//       red = 245;
//       green = 0;
//       blue = 0;
//       alpha = Math.abs(score) + score * 0.4;
//     } else {
//       // Fully transparent at score 0
//       red = 200;
//       green = 200;
//       blue = 200;
//       alpha = 1;
//     }

//     return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
//   };

//   // Modify the ScoreBox styled component to use pastel colors and horizontal layout
//   const ScoreBox = styled(Box)(({ score }) => ({
//     background: getScoreColor(score),
//     color: "black",
//     padding: "4px 8px",
//     borderRadius: "4px",
//     display: "inline-flex",
//     alignItems: "center",
//     justifyContent: "center",
//     minWidth: "60px", // Adjust width as needed
//     boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//     transition: "background-color 0.3s",
//     marginLeft: "8px", // Space from the label
//   }));

//   const StyledCard = styled(Card)({
//     marginTop: "16px", // Match grid spacing or adjust as needed
//     padding: "20px", // Optional: if you want inner padding in all cards
//   });

//   const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     fontWeight: "bold",
//     // Set a width or max width if you want to control the size of the cells
//     width: "50%",
//   }));

//   // Rendering function for the report
//   const renderReportCard = () => {
//     if (job && job.result.report) {
//       return <ReportCard reportMarkdown={job.result.report} />;
//     } else {
//       return (
//         <Typography variant="body2">No report has been generated.</Typography>
//       );
//     }
//   };

//   const renderGeopoliticalVisualization = () => {
//     if (
//       job &&
//       job.result.gsiMapping &&
//       Object.keys(job.result.gsiMapping).length > 0
//     ) {
//       const mappingData = Object.entries(job.result.gsiMapping).map(
//         ([country, description]) => ({
//           country: country.toUpperCase(), // Ensure country codes are in correct format
//           value: description,
//         })
//       );

//       return <GeopoliticalVisualization mappingData={mappingData} />;
//     } else {
//       return (
//         <Typography variant="body1">
//           No geopolitical analysis has been run.
//         </Typography>
//       );
//     }
//   };


//   const handleSetRecurringJob = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:4000/create_recurring_job', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         body: new URLSearchParams({
//           userId: currentUser.uid,
//           frequency: jobFrequency,
//           expirationTime: '2024-12-31T23:59:59Z',
//           jobId: jobId,
//         }),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log('Recurring job created:', data);
//       } else {
//         throw new Error('Failed to create recurring job');
//       }
//     }
//     catch (error) {
//       console.error('Error creating recurring job:', error);
//       alert('An error occurred while creating the recurring job.');
//     }
//   };





//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <SoftBox p={3}>
//         <Grid container spacing={3}>
//           <Grid item xs={12}>
//             <Card>
//               <CardContent>
//                 <Box display="flex" alignItems="center">
//                   <Typography variant="h4">
//                     {job.input?.name || "Job Details"}
//                   </Typography>
//                   {job.input?.specific_investment && (
//                     <Badge color="#5271ff">
//                       {job.input.specific_investment}
//                     </Badge>
//                   )}
//                   {job.input?.type && (
//                     <Badge color={getInvestmentTypeColor(job.input.type)}>
//                       {job.input.type}
//                     </Badge>
//                   )}
//                 </Box>
//                 <Box mt={3}>
//                   <Typography variant="body2" color="textSecondary">
//                     Last Run At:{" "}
//                     {job.lastRun ? format(parseISO(job.lastRun), "PPPpp") : "N/A"}
//                   </Typography>
//                 </Box>
//                 <Stack direction="row" spacing={2} mt={2}>
//                   <Button
//                     variant="contained"
//                     onClick={handleRerunAnalysis}
//                     style={{ backgroundColor: "#5271ff", color: "white" }}
//                   >
//                     Rerun Analysis
//                   </Button>
//                   <Button
//                     variant="contained"
//                     color="secondary"
//                     onClick={handleDeleteJob}
//                     style={{ color: "white" }}
//                   >
//                     Delete Job
//                   </Button>
//                 </Stack>
//                 <Box mt={3}>
//                   <FormControl component="fieldset">
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={emailnotifs}
//                           onChange={handleEmailNotifsChange}
//                         />
//                       }
//                       label="Receive E-mail Notifications"
//                     />
//                   </FormControl>
//                 </Box>
//                 <Box mt={3}>
//                   <FormControl component="fieldset">
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={recurringJob}
//                           onChange={() => setRecurringJob(!recurringJob)}
//                         />
//                       }
//                       label="Run a Recurring Job"
//                     />
//                     {recurringJob && (
//                       <Box display="flex" alignItems="center" mt={2}>
//                         <Typography variant="h6" gutterBottom>
//                           Frequency:
//                         </Typography>
//                         <Select
//                           value={jobFrequency}
//                           onChange={handleJobFrequencyChange}
//                           displayEmpty
//                           inputProps={{ 'aria-label': 'Without label' }}
//                           style={{ marginLeft: 16 }}
//                         >
//                           <MenuItem value="day">Every Day</MenuItem>
//                           <MenuItem value="week">Every Week</MenuItem>
//                           <MenuItem value="month">Every Month</MenuItem>
//                         </Select>
//                         <Button
//                           variant="contained"
//                           onClick={handleSetRecurringJob}
//                           style={{ backgroundColor: "#5271ff", color: "white", marginLeft: 16, width: 320 }}
//                         >
//                           Set Recurring Job
//                         </Button>
//                       </Box>
//                     )}
//                   </FormControl>
//                 </Box>
//               </CardContent>
//             </Card>
//           </Grid>

//           {showNewCard && (
//             <Grid item xs={12}>
//               <Card>
//                 <CardContent>
//                   <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
//                     <Box display="flex" alignItems="center">
//                       <Typography variant="h6" gutterBottom>
//                         Comparative Analysis of Relevant Investments
//                       </Typography>
//                     </Box>
//                   </Box>

//                   <Box display="flex" justifyContent="space-around" alignItems="center" mt={2}>
//                     <Box>
//                       <Typography component="div">Google:</Typography>
//                       <ScoreBox score="-0.55" style={{ mt: 1 }}>
//                         <Typography variant="body2">-0.55</Typography>
//                       </ScoreBox>
//                     </Box>
//                     <Box>
//                       <Typography component="div">Apple:</Typography>
//                       <ScoreBox score="1" style={{ mt: 1 }}>
//                         <Typography variant="body2">1</Typography>
//                       </ScoreBox>
//                     </Box>
//                     <Box>
//                       <Typography component="div">Microsoft:</Typography>
//                       <ScoreBox score="0.34" style={{ mt: 1 }}>
//                         <Typography variant="body2">0.34</Typography>
//                       </ScoreBox>
//                     </Box>
//                   </Box>
//                 </CardContent>
//               </Card>
//             </Grid>
//           )}

//           <Grid item xs={12}>
//             <Card>
//               <CardContent>
//                 <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
//                   <Box display="flex" alignItems="center">
//                     <Typography variant="h6" gutterBottom>
//                       Geopolitical Pulse
//                     </Typography>
//                     <Tooltip title="Geopolitical Pulse provides a news sentiment score reflecting the stability and geopolitical risk associated with a country or region. Key: -1: negative, +1: positive">
//                       <IconButton size="small">
//                         <InfoOutlinedIcon fontSize="small" />
//                       </IconButton>
//                     </Tooltip>
//                   </Box>

//                   <Button
//                     variant="contained"
//                     onClick={handleRunComparativeAnalysis}
//                     style={{ backgroundColor: "#5271ff", color: "white" }}
//                   >
//                     {showNewCard ? "Hide Comparative Analysis" : "Show Comparative Analysis"}
//                   </Button>
//                 </Box>

//                 {job.result?.gsiOverall !== undefined ? (
//                   <>
//                     <Box display="flex" alignItems="center" mb={2}>
//                       <Typography variant="subtitle2" gutterBottom>
//                         Score:
//                       </Typography>
//                       <ScoreBox score={job.result.gsiOverall}>
//                         <Typography variant="body2">{job.result.gsiOverall.toFixed(2)}</Typography>
//                       </ScoreBox>
//                     </Box>
//                     <Tabs value={geoTabValue} onChange={handleGeoTabChange} aria-label="Geopolitical analysis tabs">
//                       <Tab label="Visualization" />
//                       <Tab label="Events" />
//                       <Tab label="Graph" />
//                     </Tabs>
//                     {geoTabValue === 0 && (
//                       <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//                         {renderGeopoliticalVisualization()}
//                       </Grid>
//                     )}
//                     {geoTabValue === 1 && (
//                       <TableContainer component={Paper} style={{ maxHeight: 300, overflow: "auto", mt: 2, mb: 2 }}>
//                         <Table stickyHeader size="small">
//                           <TableBody>
//                             {Object.entries(job.result.gsiMapping).map(([country, description]) => (
//                               <TableRow key={country}>
//                                 <TableCell>{country}</TableCell>
//                                 <TableCell>{description}</TableCell>
//                               </TableRow>
//                             ))}
//                           </TableBody>
//                         </Table>
//                       </TableContainer>
//                     )}
//                     {geoTabValue === 2 && (
//                       <StockChart
//                         showCompAnalysis={showNewCard}
//                         specific_investment={job.input.specific_investment}
//                         labels={job["result"]["gsiLabels"]}
//                         labelData={job["result"]["gsiData"]}
//                       />
//                     )}
//                   </>
//                 ) : (
//                   <Typography variant="body1">No geopolitical analysis has been run.</Typography>
//                 )}
//               </CardContent>
//             </Card>
//           </Grid>

//           <Grid item xs={12}>{renderReportCard()}</Grid>
//         </Grid>
//       </SoftBox>
//     </DashboardLayout>
//   );
// });

// export default JobDetails;
