import React, { useState, useContext, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Slider
} from '@mui/material';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GeopoliticalVisualization from "../GeopoliticalVisualization";
import { getFirestore, doc, updateDoc, increment, getDoc, setDoc } from 'firebase/firestore';
import { AuthContext } from 'auth';
import StockChart from "layouts/GraphVisualization";
import { countryNames } from "layouts/CountryMap";
import { styled } from "@mui/material/styles";


const getScoreColor = (score) => {
  let red, green, blue, alpha;

  if (score > 0) {
    red = 0;
    green = 180;
    blue = 0;
    alpha = score - score * 0.4;
  } else if (score < 0) {
    red = 245;
    green = 0;
    blue = 0;
    alpha = Math.abs(score) + score * 0.4;
  } else {
    red = 200;
    green = 200;
    blue = 200;
    alpha = 1;
  }

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

const ScoreBox = styled(Box)(({ score }) => ({
  background: getScoreColor(score),
  color: "black",
  padding: "4px 8px",
  borderRadius: "4px",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "60px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  transition: "background-color 0.3s",
  marginLeft: "8px",
}));

const IndustryAnalysis = ({ industry, onIndustryAnalyse }) => {
  const [geoTabValue, setGeoTabValue] = useState(1);
  const [industryData, setIndustryData] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [zoom, setZoom] = useState(0.95);


  const tabs = [
    { label: "Geopolitical Events", value: 1 },
    { label: "Global Visualization", value: 0 },
    // { label: "Historical Impact", value: 2 },
  ];

  const handleGeoTabChange = (event, newValue) => {
    setGeoTabValue(newValue);
  };

  const fetchIndustryData = async () => {
    const db = getFirestore();
    const industryRef = doc(db, "industryJobs", industry);
    const docSnap = await getDoc(industryRef);

    if (docSnap.exists()) {
      setIndustryData(docSnap.data());
    } else {
      await setDoc(industryRef, {}, { merge: true }); // Create document if it doesn't exist
      console.log("Created new document for industry:", industry);
    }
  };


  const handleRerunAnalysis = async () => {
    setIsLoading(true); // Set loading state
    console.log(`Rerun analysis for ${industry}`);
    const formData = new FormData();
    formData.append("userId", currentUser.uid);
    formData.append("name", industry);
    formData.append("industry", industry);
    formData.append("specificType", industry);
    formData.append("is_gen_report", 'false');
    formData.append("is_geopolitical", 'true');
    formData.append("submissionTime", new Date().toISOString());

    try {
      const response = await fetch('https://algosight-app.com/run-industry-analysis', {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Rerun successful", data);

        const db = getFirestore();
        const industryRef = doc(db, "industryJobs", industry);
        await setDoc(industryRef, data.results, { merge: true });

        setIndustryData(data.results);

        const userMetricsRef = doc(db, 'userMetrics', currentUser.uid);
        const userMetricsDoc = await getDoc(userMetricsRef);

        if (!userMetricsDoc.exists()) {
          // If userMetrics document does not exist, create it
          await setDoc(userMetricsRef, {
            logins: 0,
            analysisJobsCreated: {
              equities: 0,
              commodities: 0,
              regions: 0,
              countries: 0
            },
            industrySelections: {
              Technology: 0,
              Healthcare: 0,
              Finance: 0,
              Energy: 0,
              ConsumerGoods: 0
            },
            assetsAnalyzed: {
              equities: 0,
              commodities: 0,
              regions: 0,
              countries: 0
            },
            industriesAnalysed: {
              Technology: 0,
              Healthcare: 0,
              Finance: 0,
              Energy: 0,
              ConsumerGoods: 0
            }
          });
        }

        
        await updateDoc(userMetricsRef, {
          [`industriesAnalysed.${industry}`]: increment(1)
        });

        console.log("Industry analysis updated.");
      } else {
        throw new Error("Failed to rerun analysis");
      }
    } catch (error) {
      console.error("Error rerunning analysis:", error);
      alert("An error occurred while rerunning the analysis.");
    } finally {
      setIsLoading(false); // Unset loading state
    }
  };


  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };
  


  useEffect(() => {
    const fetchIndustryData = async () => {
      const db = getFirestore();
      const industryRef = doc(db, "industryJobs", industry);
      const docSnap = await getDoc(industryRef);
  
      if (docSnap.exists()) {
        const industryData = docSnap.data();
  
        // Transform the scores in gsiMapping
        const transformedGsiMapping = Object.entries(industryData.gsiMapping).reduce((acc, [country, { score, statement }]) => {
          acc[country] = { score: 2 * score - 1, statement };
          return acc;
        }, {});
  
        // Update industryData with transformed gsiMapping scores
        industryData.gsiMapping = transformedGsiMapping;
  
        setIndustryData(industryData);
      } else {
        console.log("No such document!");
      }
    };
  
    fetchIndustryData();
  }, [industry]);
  
  const renderGeopoliticalVisualization = () => {
    if (industryData && industryData.gsiMapping && Object.keys(industryData.gsiMapping).length > 0) {
      const mappingData = Object.entries(industryData.gsiMapping).map(
        ([country, { score, statement }]) => ({
          country: country.toUpperCase(),
          value: `${statement} (Score: ${score?.toFixed(2)})`,
          color: getScoreColor(score)
        })
      );
  
      return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ transform: `scale(${zoom})`, transformOrigin: '50% 50%' }}>
            <GeopoliticalVisualization mappingData={mappingData} />
          </div>
          <Slider
            value={zoom}
            min={0.5}
            max={2}
            step={0.1}
            onChange={handleZoomChange}
            aria-labelledby="zoom-slider"
            style={{ marginTop: '16px', width: '80%' }}
            defaultValue={2}
          />
        </div>
      );
    } else {
      return (
        <Typography variant="body1">
          No geopolitical analysis has been run.
        </Typography>
      );
    }
  };
  
  
  

  const renderCountryTable = () => (
    industryData && industryData.gsiMapping && Object.keys(industryData.gsiMapping).length > 0 && (
      <>
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>Country Geopolitical Overview</Typography>
          <TableContainer component={Paper} style={{ maxHeight: 400, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
            <Table stickyHeader size="small">
              <TableBody>
                {Object.entries(industryData.gsiMapping).map(([countryCode, { score, statement, summary }]) => (
                  <TableRow key={countryCode}>
                    <TableCell>{countryNames[countryCode] || countryCode}</TableCell>
                    <TableCell>
                      <ScoreBox score={score}>{score.toFixed(2)}</ScoreBox>
                    </TableCell>
                    <TableCell>{summary}</TableCell>
                    <TableCell>{statement}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    )
  );
  

  const renderGeopoliticalTopicsTable = () => (
    <>
      <Box mt={2} mb={2}>
        <Typography variant="h6" gutterBottom>Geopolitical Analysis Overview</Typography>
        <TableContainer component={Paper} style={{ maxHeight: 400, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
          <Table stickyHeader size="small">
            <TableBody>
              {industryData && industryData.geopoliticalTopics && Object.entries(industryData.geopoliticalTopics).map(([topic, [score, description]]) => (
                description && (
                  <TableRow key={topic}>
                    <TableCell>{topic}</TableCell>
                    <TableCell>
                      <ScoreBox score={score}>{score?.toFixed(2)}</ScoreBox>
                    </TableCell>
                    <TableCell>{description}</TableCell>
                  </TableRow>
                )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );

  const renderNewsSources = () => (
    <>
      <Box mt={2} mb={2}>
        <Typography variant="h6" gutterBottom>News Sources</Typography>
        <Paper style={{ padding: '16px', marginTop: '16px', maxWidth: '600px' }}>
          <List>
            {industryData && industryData.newsSources && industryData.newsSources.map((source, index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={source}
                  primaryTypographyProps={{
                    fontSize: '1rem',
                    // color: '#4285f4',
                    // fontWeight: 'bold'
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
    </>
  );

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {industry} Industry Analysis
        </Typography>
        <Box mt={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h6" gutterBottom>
                Geopolitical Pulse
              </Typography>
              <Tooltip title="Geopolitical Pulse provides a news sentiment score reflecting the stability and geopolitical risk associated with a country or region. Key: -1: negative, +1: positive">
                <IconButton size="small">
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Button
              variant="contained"
              onClick={handleRerunAnalysis}
              style={{ backgroundColor: "#5271ff", color: "white", marginLeft: '8px' }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Rerun Analysis'}
            </Button>

          </Box>
          {industryData ? (
            <>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle2" gutterBottom>
                  Score:
                </Typography>
                <ScoreBox score={industryData.gsiOverall}>
                  <Typography variant="body2">{industryData.gsiOverall?.toFixed(2)}</Typography>
                </ScoreBox>
              </Box>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Last Run: {new Date(industryData.lastRun).toLocaleString()}
              </Typography>
              <Tabs value={geoTabValue} onChange={handleGeoTabChange} aria-label="Geopolitical analysis tabs">
                {tabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
              {geoTabValue === 0 && (
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {renderGeopoliticalVisualization()}
                </Grid>
              )}
              {geoTabValue === 1 && (
                <>
                  {renderGeopoliticalTopicsTable()}
                  {renderCountryTable()}
                  {renderNewsSources()}
                </>
              )}
              {geoTabValue === 2 && industryData.gsiLabels && industryData.gsiData && (
                <StockChart
                  showCompAnalysis={false}
                  specific_investment={industry}
                  labels={industryData.gsiLabels}
                  labelData={industryData.gsiData}
                />
              )}
            </>
          ) : (
            <Typography variant="body1">No analysis run.</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default IndustryAnalysis;




// // src/layouts/DashboardPage/IndustryAnalysis.js

// import React, { useState, useContext } from 'react';
// import {
//   Card,
//   CardContent,
//   Typography,
//   Box,
//   Grid,
//   Tooltip,
//   IconButton,
//   Tabs,
//   Tab,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
//   Button,
//   List,
//   ListItem,
//   ListItemText,
// } from '@mui/material';
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import GeopoliticalVisualization from "../GeopoliticalVisualization"; // Assuming this is the correct path
// import { getFirestore, doc, updateDoc, increment, getDoc, setDoc } from 'firebase/firestore';
// import { AuthContext } from 'auth';

// // Sample mock data for presentation purposes
// const mockData = {
//   gsiOverall: 0.75,
//   lastRun: "2023-07-19T15:30:00Z",
//   gsiMapping: {
//     US: { score: 0.8, summary: "Positive sentiment about economic growth." },
//     CN: { score: -0.6, summary: "Concerns about political stability." },
//     RU: { score: -0.9, summary: "Negative sentiment due to geopolitical tensions." }
//   },
//   geopoliticalTopics: {
//     "Economic Growth": { score: 0.8, description: "Positive outlook on global economic growth." },
//     "Political Stability": { score: -0.6, description: "Concerns over political stability in certain regions." },
//     "Trade Relations": { score: 0.4, description: "Improved trade relations among major economies." }
//   },
//   newsSources: [
//     "BBC News",
//     "CNN",
//     "Reuters",
//     "The New York Times",
//     "Al Jazeera"
//   ],
//   gsiLabels: ["2023-07-01", "2023-07-05", "2023-07-10", "2023-07-15", "2023-07-19"],
//   gsiData: [0.6, 0.7, 0.8, 0.7, 0.75]
// };

// const getScoreColor = (score) => {
//   let red, green, blue, alpha;

//   if (score > 0) {
//     red = 0;
//     green = 180; // Slightly brighter green
//     blue = 0;
//     alpha = score - score * 0.4;
//   } else if (score < 0) {
//     red = 245;
//     green = 0;
//     blue = 0;
//     alpha = Math.abs(score) + score * 0.4;
//   } else {
//     red = 200;
//     green = 200;
//     blue = 200;
//     alpha = 1;
//   }

//   return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
// };

// const IndustryAnalysis = ({ industry, onIndustryAnalyse }) => {
//   const [geoTabValue, setGeoTabValue] = useState(0);
//   const { currentUser } = useContext(AuthContext);

//   const handleGeoTabChange = (event, newValue) => {
//     setGeoTabValue(newValue);
//   };

//   const handleRerunAnalysis = async () => {
//     console.log(`Rerun analysis for ${industry}`);
//     // Add your rerun analysis logic here

//     const db = getFirestore();
//     const userMetricsRef = doc(db, 'userMetrics', currentUser.uid);
//     const userMetricsDoc = await getDoc(userMetricsRef);

//     if (!userMetricsDoc.exists()) {
//       // If userMetrics document does not exist, create it
//       await setDoc(userMetricsRef, {
//         logins: 0,
//         analysisJobsCreated: {
//           equities: 0,
//           commodities: 0,
//           regions: 0,
//           countries: 0
//         },
//         industrySelections: {
//           Technology: 0,
//           Healthcare: 0,
//           Finance: 0,
//           Energy: 0,
//           ConsumerGoods: 0
//         },
//         assetsAnalyzed: {
//           equities: 0,
//           commodities: 0,
//           regions: 0,
//           countries: 0
//         },
//         industriesAnalysed: {
//           Technology: 0,
//           Healthcare: 0,
//           Finance: 0,
//           Energy: 0,
//           ConsumerGoods: 0
//         }
//       });
//     }

//     // Update the analysisJobsCreated counter for the industry type
//     await updateDoc(userMetricsRef, {
//       [`industriesAnalysed.${industry}`]: increment(1)
//     });

//     console.log("Analysis job and industry analysis count updated.");
//     if (onIndustryAnalyse) onIndustryAnalyse();
//   };

//   const renderGeopoliticalVisualization = () => {
//     if (Object.keys(mockData.gsiMapping).length > 0) {
//       const mappingData = Object.entries(mockData.gsiMapping).map(
//         ([country, description]) => ({
//           country: country.toUpperCase(), // Ensure country codes are in correct format
//           value: description,
//         })
//       );

//       return <GeopoliticalVisualization mappingData={mappingData} />;
//     } else {
//       return (
//         <Typography variant="body1">
//           No geopolitical analysis has been run.
//         </Typography>
//       );
//     }
//   };

//   const renderCountryTable = () => (
//     <>
//       <Typography variant="h6" gutterBottom>Country Sentiment Scores</Typography>
//       <TableContainer component={Paper} style={{ maxHeight: 200, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
//         <Table stickyHeader size="small">
//           <TableBody>
//             {Object.entries(mockData.gsiMapping).map(([country, { score, summary }]) => (
//               <TableRow key={country}>
//                 <TableCell>{country}</TableCell>
//                 <TableCell>{score}</TableCell>
//                 <TableCell>{summary}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </>
//   );

//   const renderGeopoliticalTopicsTable = () => (
//     <>
//       <Typography variant="h6" gutterBottom>Geopolitical Topics Breakdown</Typography>
//       <TableContainer component={Paper} style={{ maxHeight: 200, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
//         <Table stickyHeader size="small">
//           <TableBody>
//             {Object.entries(mockData.geopoliticalTopics).map(([topic, { score, description }]) => (
//               <TableRow key={topic}>
//                 <TableCell>{topic}</TableCell>
//                 <TableCell>{score}</TableCell>
//                 <TableCell>{description}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </>
//   );

//   const renderNewsSources = () => (
//     <>
//       <Typography variant="h6" gutterBottom>News Sources</Typography>
//       <Paper style={{ padding: '16px', marginTop: '16px' }}>
//         <List>
//           {mockData.newsSources.map((source, index) => (
//             <ListItem key={index} divider>
//               <ListItemText 
//                 primary={source} 
//                 primaryTypographyProps={{ 
//                   fontSize: '1rem', // Smaller font size
//                   color: '#4285f4', // Default blue color
//                   fontWeight: 'bold' // Bold text
//                 }} 
//               />
//             </ListItem>
//           ))}
//         </List>
//       </Paper>
//     </>
//   );

//   return (
//     <Card>
//       <CardContent>
//         <Typography variant="h5" gutterBottom>
//           {industry} Industry Analysis
//         </Typography>
//         <Box mt={2}>
//           <Box display="flex" alignItems="center" justifyContent="space-between">
//             <Box display="flex" alignItems="center">
//               <Typography variant="h6" gutterBottom>
//                 Geopolitical Pulse
//               </Typography>
//               <Tooltip title="Geopolitical Pulse provides a news sentiment score reflecting the stability and geopolitical risk associated with a country or region. Key: -1: negative, +1: positive">
//                 <IconButton size="small">
//                   <InfoOutlinedIcon fontSize="small" />
//                 </IconButton>
//               </Tooltip>
//             </Box>
//             <Button
//               variant="contained"
//               onClick={handleRerunAnalysis}
//               style={{ backgroundColor: "#5271ff", color: "white", marginLeft: '8px' }}
//             >
//               Rerun Analysis
//             </Button>
//           </Box>
//           <Box display="flex" alignItems="center" mb={2}>
//             <Typography variant="subtitle2" gutterBottom>
//               Score:
//             </Typography>
//             <Box
//               style={{
//                 background: getScoreColor(mockData.gsiOverall),
//                 color: 'black',
//                 padding: '4px 8px',
//                 borderRadius: '4px',
//                 display: 'inline-flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 minWidth: '60px',
//                 boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//                 transition: 'background-color 0.3s',
//                 marginLeft: '8px',
//               }}
//             >
//               <Typography variant="body2">{mockData.gsiOverall.toFixed(2)}</Typography>
//             </Box>
//           </Box>
//           <Typography variant="body2" color="textSecondary" gutterBottom>
//             Last Run: {new Date(mockData.lastRun).toLocaleString()}
//           </Typography>
//           <Tabs value={geoTabValue} onChange={handleGeoTabChange} aria-label="Geopolitical analysis tabs">
//             <Tab label="Visualization" />
//             <Tab label="Events" />
//           </Tabs>
//           {geoTabValue === 0 && (
//             <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//               {renderGeopoliticalVisualization()}
//             </Grid>
//           )}
//           {geoTabValue === 1 && (
//             <>
//               {renderCountryTable()}
//               {renderGeopoliticalTopicsTable()}
//               {renderNewsSources()}
//             </>
//           )}
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

// export default IndustryAnalysis;
