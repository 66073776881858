// src/layouts/DashboardPage/index.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Link,
  Paper,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { parseISO, format } from "date-fns";
import { collection, getFirestore, query, where, onSnapshot, doc, updateDoc, increment, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'auth';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import IndustryAnalysis from './IndustryAnalysis';
import AnalysisRunCard from './AnalysisRunCard';
import { useMediaQuery, useTheme } from '@mui/material';
import CreateJobButton from 'layouts/CreateJobButton';

// Sample mock data for the economic calendar
const economicCalendarEvents = [
  { date: "2024-08-01", event: "Interest Rate Decision", country: "BR", description: "Brazil's interest rate decision." },
  { date: "2024-08-01", event: "Judo Bank Manufacturing PMI Final JUL", country: "AU", description: "Australia's Judo Bank Manufacturing PMI Final for July." },
  { date: "2024-08-01", event: "Foreign Bond Investment JUL/27", country: "JP", description: "Japan's foreign bond investment." },
  { date: "2024-08-01", event: "Stock Investment by Foreigners JUL/27", country: "JP", description: "Japan's stock investment by foreigners." },
  { date: "2024-08-01", event: "Exports YoY JUL", country: "KR", description: "South Korea's exports year-on-year for July." },
  { date: "2024-08-01", event: "Balance of Trade JUL", country: "KR", description: "South Korea's balance of trade for July." },
  { date: "2024-08-01", event: "Imports YoY JUL", country: "KR", description: "South Korea's imports year-on-year for July." },
  { date: "2024-08-01", event: "S&P Global Manufacturing PMI JUL", country: "ID", description: "Indonesia's S&P Global Manufacturing PMI for July." },
  { date: "2024-08-01", event: "Jibun Bank Manufacturing PMI Final JUL", country: "JP", description: "Japan's Jibun Bank Manufacturing PMI Final for July." },
  { date: "2024-08-01", event: "S&P Global Manufacturing PMI JUL", country: "KR", description: "South Korea's S&P Global Manufacturing PMI for July." },
  { date: "2024-08-01", event: "Balance of Trade JUN", country: "AU", description: "Australia's balance of trade for June." },
  { date: "2024-08-01", event: "Export Prices QoQ Q2", country: "AU", description: "Australia's export prices quarter-on-quarter for Q2." },
  { date: "2024-08-01", event: "Exports MoM JUN", country: "AU", description: "Australia's exports month-on-month for June." },
  { date: "2024-08-01", event: "Import Prices QoQ Q2", country: "AU", description: "Australia's import prices quarter-on-quarter for Q2." },
  { date: "2024-08-01", event: "Imports MoM JUN", country: "AU", description: "Australia's imports month-on-month for June." },
  { date: "2024-08-01", event: "Caixin Manufacturing PMI JUL", country: "CN", description: "China's Caixin Manufacturing PMI for July." },
  { date: "2024-08-01", event: "Inflation Rate YoY JUL", country: "ID", description: "Indonesia's inflation rate year-on-year for July." },
  { date: "2024-08-01", event: "Core Inflation Rate YoY JUL", country: "ID", description: "Indonesia's core inflation rate year-on-year for July." },
  { date: "2024-08-01", event: "Inflation Rate MoM JUL", country: "ID", description: "Indonesia's inflation rate month-on-month for July." },
  { date: "2024-08-01", event: "Tourist Arrivals YoY JUN", country: "ID", description: "Indonesia's tourist arrivals year-on-year for June." },
  { date: "2024-08-01", event: "HSBC Manufacturing PMI Final JUL", country: "IN", description: "India's HSBC Manufacturing PMI Final for July." },
  { date: "2024-08-01", event: "S&P Global Manufacturing PMI JUL", country: "RU", description: "Russia's S&P Global Manufacturing PMI for July." },
  { date: "2024-08-01", event: "Nationwide Housing Prices MoM JUL", country: "GB", description: "UK's nationwide housing prices month-on-month for July." },
  { date: "2024-08-01", event: "Nationwide Housing Prices YoY JUL", country: "GB", description: "UK's nationwide housing prices year-on-year for July." },
  { date: "2024-08-01", event: "Commodity Prices YoY JUL", country: "AU", description: "Australia's commodity prices year-on-year for July." },
  { date: "2024-08-01", event: "Istanbul Chamber of Industry Manufacturing PMI JUL", country: "TR", description: "Turkey's Istanbul Chamber of Industry Manufacturing PMI for July." },
  { date: "2024-08-01", event: "HCOB Manufacturing PMI JUL", country: "ES", description: "Spain's HCOB Manufacturing PMI for July." },
  { date: "2024-08-01", event: "HCOB Manufacturing PMI JUL", country: "IT", description: "Italy's HCOB Manufacturing PMI for July." },
  { date: "2024-08-01", event: "HCOB Manufacturing PMI Final JUL", country: "FR", description: "France's HCOB Manufacturing PMI Final for July." },
  { date: "2024-08-01", event: "HCOB Manufacturing PMI Final JUL", country: "DE", description: "Germany's HCOB Manufacturing PMI Final for July." },
  { date: "2024-08-01", event: "HCOB Manufacturing PMI Final JUL", country: "EA", description: "Euro Area's HCOB Manufacturing PMI Final for July." },
  { date: "2024-08-01", event: "Unemployment Rate JUN", country: "IT", description: "Italy's unemployment rate for June." },
  { date: "2024-08-01", event: "S&P Global Manufacturing PMI Final JUL", country: "GB", description: "UK's S&P Global Manufacturing PMI Final for July." },
  { date: "2024-08-01", event: "Bonos Auction", country: "ES", description: "Spain's Bonos Auction." },
  { date: "2024-08-01", event: "Index-Linked Obligacion Auction", country: "ES", description: "Spain's Index-Linked Obligacion Auction." },
  { date: "2024-08-01", event: "Obligacion Auction", country: "ES", description: "Spain's Obligacion Auction." },
  { date: "2024-08-01", event: "Unemployment Rate JUN", country: "EA", description: "Euro Area's unemployment rate for June." },
  { date: "2024-08-01", event: "10-Year OAT Auction", country: "FR", description: "France's 10-Year OAT Auction." },
  { date: "2024-08-01", event: "20-Year OAT Auction", country: "FR", description: "France's 20-Year OAT Auction." },
  { date: "2024-08-01", event: "30-Year OAT Auction", country: "FR", description: "France's 30-Year OAT Auction." },
  { date: "2024-08-01", event: "ABSA Manufacturing PMI JUL", country: "ZA", description: "South Africa's ABSA Manufacturing PMI for July." },
  { date: "2024-08-01", event: "Foreign Exchange Reserves JUN", country: "MX", description: "Mexico's foreign exchange reserves." },
  { date: "2024-08-01", event: "MPC Meeting Summary", country: "TR", description: "Turkey's MPC Meeting Summary." },
  { date: "2024-08-01", event: "BoE Interest Rate Decision", country: "GB", description: "Bank of England's interest rate decision." },
  { date: "2024-08-01", event: "BoE Monetary Policy Report", country: "GB", description: "Bank of England's monetary policy report." },
  { date: "2024-08-01", event: "BoE MPC Vote Cut", country: "GB", description: "Bank of England's MPC vote cut." },
  { date: "2024-08-01", event: "BoE MPC Vote Hike", country: "GB", description: "Bank of England's MPC vote hike." },
  { date: "2024-08-01", event: "BoE MPC Vote Unchanged", country: "GB", description: "Bank of England's MPC vote unchanged." },
  { date: "2024-08-01", event: "MPC Meeting Minutes", country: "GB", description: "Bank of England's MPC meeting minutes." },
  { date: "2024-08-01", event: "Foreign Exchange Reserves JUL/26", country: "TR", description: "Turkey's foreign exchange reserves." },
  { date: "2024-08-01", event: "Challenger Job Cuts JUL", country: "US", description: "USA's challenger job cuts for July." },
  { date: "2024-08-01", event: "Business Confidence JUL", country: "MX", description: "Mexico's business confidence for July." },
  { date: "2024-08-01", event: "Initial Jobless Claims JUL/27", country: "US", description: "USA's initial jobless claims for July 27." },
  { date: "2024-08-01", event: "Nonfarm Productivity QoQ Prel Q2", country: "US", description: "USA's nonfarm productivity quarter-on-quarter preliminary for Q2." },
  { date: "2024-08-01", event: "Unit Labour Costs QoQ Prel Q2", country: "US", description: "USA's unit labour costs quarter-on-quarter preliminary for Q2." },
  { date: "2024-08-01", event: "Continuing Jobless Claims JUL/20", country: "US", description: "USA's continuing jobless claims for July 20." },
  { date: "2024-08-01", event: "Jobless Claims 4-week Average JUL/27", country: "US", description: "USA's jobless claims 4-week average for July 27." },
  { date: "2024-08-01", event: "S&P Global Manufacturing PMI JUL", country: "BR", description: "Brazil's S&P Global Manufacturing PMI for July." },
  { date: "2024-08-01", event: "S&P Global Manufacturing PMI JUL", country: "CA", description: "Canada's S&P Global Manufacturing PMI for July." },
  { date: "2024-08-01", event: "S&P Global Manufacturing PMI Final JUL", country: "US", description: "USA's S&P Global Manufacturing PMI Final for July." },
  { date: "2024-08-01", event: "ISM Manufacturing PMI JUL", country: "US", description: "USA's ISM Manufacturing PMI for July." },
  { date: "2024-08-01", event: "ISM Manufacturing Employment JUL", country: "US", description: "USA's ISM Manufacturing Employment for July." },
  { date: "2024-08-01", event: "Construction Spending MoM JUN", country: "US", description: "USA's construction spending month-on-month for June." },
  { date: "2024-08-01", event: "ISM Manufacturing New Orders JUL", country: "US", description: "USA's ISM Manufacturing New Orders for July." },
  { date: "2024-08-01", event: "ISM Manufacturing Prices JUL", country: "US", description: "USA's ISM Manufacturing Prices for July." },
  { date: "2024-08-01", event: "EIA Natural Gas Stocks Change JUL/26", country: "US", description: "USA's EIA natural gas stocks change for July 26." },
  { date: "2024-08-01", event: "S&P Global Manufacturing PMI JUL", country: "MX", description: "Mexico's S&P Global Manufacturing PMI for July." },
  { date: "2024-08-01", event: "4-Week Bill Auction", country: "US", description: "USA's 4-week bill auction." },
  { date: "2024-08-01", event: "8-Week Bill Auction", country: "US", description: "USA's 8-week bill auction." },
  { date: "2024-08-01", event: "10-Year NTN-F Auction", country: "BR", description: "Brazil's 10-year NTN-F auction." },
  { date: "2024-08-01", event: "2-Year LTN Auction", country: "BR", description: "Brazil's 2-year LTN auction." },
  { date: "2024-08-01", event: "2-Year Bond Auction", country: "CA", description: "Canada's 2-year bond auction." },
  { date: "2024-08-01", event: "15-Year Mortgage Rate JUL/31", country: "US", description: "USA's 15-year mortgage rate for July 31." },
  { date: "2024-08-01", event: "30-Year Mortgage Rate JUL/31", country: "US", description: "USA's 30-year mortgage rate for July 31." },
  { date: "2024-08-01", event: "CoreLogic Dwelling Prices MoM JUL", country: "AU", description: "Australia's CoreLogic dwelling prices month-on-month for July." },
  { date: "2024-08-01", event: "6-Month T-Bill Auction", country: "SG", description: "Singapore's 6-month T-Bill auction." },
  { date: "2024-08-01", event: "30-Year KTB Auction", country: "KR", description: "South Korea's 30-year KTB auction." },
  { date: "2024-08-01", event: "New Car Sales YoY JUL", country: "ES", description: "Spain's new car sales year-on-year for July." },
  { date: "2024-08-01", event: "Total New Vehicle Sales JUL", country: "ZA", description: "South Africa's total new vehicle sales for July." },
  { date: "2024-08-01", event: "New Car Registrations YoY JUL", country: "IT", description: "Italy's new car registrations year-on-year for July." }
];

// const economicCalendarEvents = [
//   { date: "2024-07-20", event: "GDP Growth Rate", description: "Gross Domestic Product growth rate measures the annualized change in the inflation-adjusted value of all goods and services produced by the economy." },
//   { date: "2024-07-21", event: "Unemployment Rate", description: "The percentage of the total workforce that is unemployed and actively seeking employment during the previous month." },
//   { date: "2024-07-22", event: "CPI Inflation Rate", description: "Consumer Price Index measures the change in the price of goods and services from the perspective of the consumer." },
//   { date: "2024-07-23", event: "Retail Sales", description: "Measures the change in the total value of sales at the retail level." },
//   { date: "2024-07-24", event: "FOMC Meeting", description: "Federal Open Market Committee meeting to discuss monetary policy and set interest rates." }
// ];

// Sample mock data for the upcoming geopolitical summits
const geopoliticalSummits = [
  { date: "2024-08-19", event: "US Democratic Party National Convention", country: "US", description: "Delegates in Chicago (Illinois state) will select the party’s nominees for president and vice-president in the 2024 US presidential election." },
  // { date: "2024-08-28", event: "2024 Summer Paralympics", country: "Global", description: "The Paralympic Games will take place in Paris (France) and various venues elsewhere." },
  { date: "2024-09-01", event: "Snap parliamentary elections", country: "Azerbaijan", description: "President Ilham Aliyev’s New Azerbaijan Party is highly likely to win in a landslide." },
  { date: "2024-09-01", event: "Saxony and Thuringia state elections", country: "Germany", description: "Saxony and Thuringia states are due to hold state elections." },
  { date: "2024-09-01", event: "Legislative elections", country: "Czechia", description: "Czechia will see regional elections and the election of one-third of the Senate (upper house), serving as gauge of public sentiment for political parties ahead of the 2025 general election." },
  { date: "2024-09-07", event: "Presidential election", country: "Algeria", description: "Authorities announced anticipated presidential elections for September 2024 instead of December 2024." },
  { date: "2024-09-10", event: "Legislative elections", country: "Jordan", description: "Voter turnout is expected to be low, as occurred during the 2020 general election." },
  { date: "2024-09-10", event: "Opening of the UN General Assembly", country: "Global", description: "The UN General Assembly will run for approximately two weeks." },
  { date: "2024-09-15", event: "Presidential election", country: "Romania", description: "According to polls, independent candidate Mircea Geoană is estimated to secure the most votes, followed closely by the Prime Minister and leader of the Social Democrat Party (PSD), Marcel Ciolacu." },
  { date: "2024-09-17", event: "Presidential election", country: "Sri Lanka", description: "Presidential elections should be held between 17 September and 16 October 2024, according to the Constitution." },
  { date: "2024-09-22", event: "Brandenburg state election", country: "Germany", description: "Brandenberg state is due to hold state elections." },
  { date: "2024-09-22", event: "The UN Summit of the Future", country: "Global", description: "The summit aims to enhance cooperation on critical challenges and address gaps in global governance, reaffirm existing commitments including to the Sustainable Development Goals (SDGs) and the United Nations Charter, and move towards a reinvigorated multilateral system." }
];


const industries = ["Technology", "Healthcare", "Finance", "Energy", "Consumer Goods"];

const DashboardPage = () => {
  
  const [selectedIndustries, setSelectedIndustries] = useState(["Technology", "Energy"]);
  const [recentJobs, setRecentJobs] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [selectedCountries, setSelectedCountries] = useState(["All"]);
  
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  
  
  useEffect(() => {
    if (currentUser?.uid) {
      const db = getFirestore();
      const jobsRef = collection(db, "jobs");
      const q = query(jobsRef, where("userId", "==", currentUser.uid));
      
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const jobsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data().input,
          processed_at: doc.data().input.submissionTime,
          lastRun: doc.data().lastRun,
          result: doc.data().result  // Assuming there is a 'result' field in your data
        }));
        
        // Sort jobs by submission time in descending order and take the most recent two
        const sortedJobs = jobsArray.sort((a, b) => parseISO(b.processed_at) - parseISO(a.processed_at)).slice(0, 2);
        
        setRecentJobs(sortedJobs);
      });
  
      return () => unsubscribe();
    }
  }, [currentUser]);
  
  
  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCountries(
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  
  const handleIndustryChange = async (event, newIndustries) => {
    if (newIndustries.length <= 2) {
      setSelectedIndustries(newIndustries);

      const db = getFirestore();
      const userMetricsRef = doc(db, 'userMetrics', currentUser.uid);
      const userMetricsDoc = await getDoc(userMetricsRef);

      if (!userMetricsDoc.exists()) {
        // If userMetrics document does not exist, create it
        await setDoc(userMetricsRef, {
          logins: 0,
          analysisJobsCreated: {
            equities: 0,
            commodities: 0,
            regions: 0,
            countries: 0
          },
          industrySelections: {
            Technology: 0,
            Healthcare: 0,
            Finance: 0,
            Energy: 0,
            ConsumerGoods: 0
          },
          assetsAnalyzed: {
            equities: 0,
            commodities: 0,
            regions: 0,
            countries: 0
          },
          industriesAnalysed: {
            Technology: 0,
            Healthcare: 0,
            Finance: 0,
            Energy: 0,
            ConsumerGoods: 0
          }
        });
      }

      // Update the industry selections count
      newIndustries.forEach(async (industry) => {
        await updateDoc(userMetricsRef, {
          [`industrySelections.${industry}`]: increment(1)
        });
      });

      console.log("Industry selections updated.");
    }
  };


  const handleJobClick = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  const handleRerunAnalysis = (jobId) => {
    console.log(`Rerun analysis for job ID: ${jobId}`);
    // Add your rerun analysis logic here
  };

  const handleViewAll = () => {
    navigate('/analysis-jobs');
  };

  const sortedEconomicCalendarEvents = [...economicCalendarEvents].sort((a, b) => new Date(a.date) - new Date(b.date));
  const sortedGeopoliticalSummits = [...geopoliticalSummits].sort((a, b) => new Date(a.date) - new Date(b.date));

  const filteredEvents = sortedEconomicCalendarEvents.filter(event => 
    selectedCountries.includes("All") || selectedCountries.includes(event.country)
  );
  
  const renderEventItem = (event, index) => (
    <Tooltip
      key={index}
      title={<>
              <Typography variant="body2">{event.description}</Typography>
              {event.location && <Typography variant="body2"><strong>Location:</strong> {event.location}</Typography>}
              {event.significance && <Typography variant="body2"><strong>Significance:</strong> {event.significance}</Typography>}
             </>}
      arrow
      placement="top"
    >
      <ListItem divider>
        <ListItemText
          primary={
            <Typography variant="h6" color="#5271ff">
              {event.event}
            </Typography>
          }
          secondary={
            <>
              <Typography variant="body2" color="textSecondary">
                {event.date}
              </Typography>
              <Typography variant="body2" style={{ color: "#DF6868" }}>
                {event.country}
              </Typography>
            </>
          }
        />
      </ListItem>
    </Tooltip>
  );
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box my={3}>
        <Typography variant="h3" align="center" gutterBottom>
          Geopolitical Impact Dashboard
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={5}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom mt={1}>
                Industry Geopolitical Impact
              </Typography>
              <Box mt={2} mb={2}>
                <Typography variant="subtitle2" gutterBottom>
                  Select up to two industries
                </Typography>
                <ToggleButtonGroup
                  value={selectedIndustries}
                  onChange={handleIndustryChange}
                  aria-label="industries"
                  sx={{ mt: 2, mb: 4 }}
                >
                  {industries.map((industry) => (
                    <ToggleButton 
                      key={industry} 
                      value={industry} 
                      aria-label={industry}
                      sx={{
                        fontSize: '0.75rem', // Smaller font size
                        color: selectedIndustries.includes(industry) ? "#5271ff" : "default",
                        backgroundColor: selectedIndustries.includes(industry) ? "#f5f5f5" : "default",
                        '&:hover': {
                          backgroundColor: selectedIndustries.includes(industry) ? "#e8e8e8" : "#f0f0f0",
                        },
                        '&.Mui-selected': {
                          backgroundColor: "#f5f5f5",
                          color: "#5271ff",
                          '&:hover': {
                            backgroundColor: "#e8e8e8",
                          },
                        }
                      }}
                    >
                      {industry}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>
              <Grid container spacing={2}>
                {selectedIndustries.map((industry) => (
                  <Grid item xs={12} key={industry}>
                    <IndustryAnalysis industry={industry}/>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                <Typography variant="h5" gutterBottom>
                  Recent Analysis Runs
                </Typography>
                <Link
                  component="button"
                  variant="body2"
                  onClick={handleViewAll}
                  style={{ color: "#5271ff", cursor: 'pointer', fontWeight: 'bold' }}
                >
                  View All Analysis Runs
                </Link>
              </Box>
              <Grid container spacing={2}>
                <Box mt={3}>
                  {recentJobs.length === 0 ? (
                    <Box mt={4} m={2}>
                      <Typography variant="h6" gutterBottom>No recent analysis runs found.</Typography>
                      <CreateJobButton />
                    </Box>
                  ) : (
                    recentJobs.map((job) => (
                      <Grid item xs={12} key={job.id}>
                        <AnalysisRunCard 
                          job={job} 
                          onViewDetails={() => handleJobClick(job.id)} 
                          onRerunAnalysis={() => handleRerunAnalysis(job.id)} 
                        />
                      </Grid>
                    ))
                  )}
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Geopolitical Calendar
                  </Typography>
                  <List sx={{ maxHeight: 300, overflow: "auto" }}>
                    {sortedGeopoliticalSummits.map(renderEventItem)}
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Economic Calendar
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Filter by Country
                  </Typography>
                  <Box>
                    <FormControl fullWidth>
                      <Select
                        multiple
                        value={selectedCountries}
                        onChange={handleCountryChange}
                        renderValue={(selected) => selected.join(', ')}
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="US">USA</MenuItem>
                        <MenuItem value="BR">Brazil</MenuItem>
                        <MenuItem value="AU">Australia</MenuItem>
                        <MenuItem value="JP">Japan</MenuItem>
                        <MenuItem value="KR">South Korea</MenuItem>
                        <MenuItem value="CN">China</MenuItem>
                        <MenuItem value="ID">Indonesia</MenuItem>
                        <MenuItem value="IN">India</MenuItem>
                        <MenuItem value="RU">Russia</MenuItem>
                        <MenuItem value="GB">UK</MenuItem>
                        <MenuItem value="ES">Spain</MenuItem>
                        <MenuItem value="IT">Italy</MenuItem>
                        <MenuItem value="FR">France</MenuItem>
                        <MenuItem value="DE">Germany</MenuItem>
                        <MenuItem value="EA">Euro Area</MenuItem>
                        <MenuItem value="TR">Turkey</MenuItem>
                        <MenuItem value="ZA">South Africa</MenuItem>
                        <MenuItem value="MX">Mexico</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box style={{ maxHeight: '300px', overflow: 'auto' }} mt={2}>
                    <List>
                      {filteredEvents.map(renderEventItem)}
                    </List>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default DashboardPage;










// // src/layouts/DashboardPage/index.js

// import React, { useState, useEffect, useContext } from 'react';
// import {
//   Card,
//   CardContent,
//   Typography,
//   Grid,
//   ToggleButton,
//   ToggleButtonGroup,
//   Box,
//   Link,
//   Paper,
// } from '@mui/material';
// import { collection, getFirestore, query, where, onSnapshot } from 'firebase/firestore';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from 'auth';
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import IndustryAnalysis from './IndustryAnalysis';
// import AnalysisRunCard from './AnalysisRunCard';

// // Sample mock data for the economic calendar
// const economicCalendarEvents = [
//   { date: "2024-07-20", event: "GDP Growth Rate" },
//   { date: "2024-07-21", event: "Unemployment Rate" },
//   { date: "2024-07-22", event: "CPI Inflation Rate" },
//   { date: "2024-07-23", event: "Retail Sales" },
//   { date: "2024-07-24", event: "FOMC Meeting" }
// ];

// // Sample mock data for the upcoming geopolitical summits
// const geopoliticalSummits = [
//   { date: "2024-07-25", event: "G7 Summit" },
//   { date: "2024-08-01", event: "UN General Assembly" },
//   { date: "2024-09-15", event: "NATO Summit" }
// ];

// const industries = ["Technology", "Healthcare", "Finance", "Energy", "Consumer Goods"];

// const DashboardPage = () => {
//   const [selectedIndustries, setSelectedIndustries] = useState([]);
//   const [recentJobs, setRecentJobs] = useState([]);
//   const { currentUser } = useContext(AuthContext);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (currentUser?.uid) {
//       const db = getFirestore();
//       const jobsRef = collection(db, "jobs");
//       const q = query(jobsRef, where("userId", "==", currentUser.uid));
      
//       const unsubscribe = onSnapshot(q, (querySnapshot) => {
//         const jobsArray = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data().input,
//           processed_at: doc.data().input.submissionTime,
//           lastRun: doc.data().lastRun,
//           result: doc.data().result  // Assuming there is a 'result' field in your data
//         }));
//         setRecentJobs(jobsArray.slice(0, 2));  // Get the most recent 2 jobs
//       });

//       return () => unsubscribe();
//     }
//   }, [currentUser]);

//   const handleIndustryChange = (event, newIndustries) => {
//     if (newIndustries.length <= 2) {
//       setSelectedIndustries(newIndustries);
//     }
//   };

//   const handleJobClick = (jobId) => {
//     navigate(`/job/${jobId}`);
//   };

//   const handleRerunAnalysis = (jobId) => {
//     console.log(`Rerun analysis for job ID: ${jobId}`);
//     // Add your rerun analysis logic here
//   };

//   const handleViewAll = () => {
//     navigate('/analysis-jobs');
//   };

//   const renderEventCard = (event, index) => (
//     <Paper
//       key={index}
//       elevation={2}
//       sx={{
//         p: 2,
//         mb: 2,
//         backgroundColor: "#f9f9f9",
//         '&:hover': {
//           backgroundColor: "#f0f0f0",
//         }
//       }}
//     >
//       <Typography variant="h6" color="#5271ff">
//         {event.date}
//       </Typography>
//       <Typography variant="subtitle1">
//         {event.event}
//       </Typography>
//     </Paper>
//   );

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <Card>
//         <CardContent>
//           <Typography variant="h5" gutterBottom>
//             Industry Geopolitical Impact
//           </Typography>
//           <Box mt={2} mb={2}>
//             <Typography variant="subtitle2" gutterBottom>
//               Select up to two industries
//             </Typography>
//             <ToggleButtonGroup
//               value={selectedIndustries}
//               onChange={handleIndustryChange}
//               aria-label="industries"
//               sx={{ mt: 2, mb: 4 }}
//             >
//               {industries.map((industry) => (
//                 <ToggleButton 
//                   key={industry} 
//                   value={industry} 
//                   aria-label={industry}
//                   sx={{
//                     color: selectedIndustries.includes(industry) ? "#5271ff" : "default",
//                     backgroundColor: selectedIndustries.includes(industry) ? "#f5f5f5" : "default",
//                     '&:hover': {
//                       backgroundColor: selectedIndustries.includes(industry) ? "#e8e8e8" : "#f0f0f0",
//                     },
//                     '&.Mui-selected': {
//                       backgroundColor: "#f5f5f5",
//                       color: "#5271ff",
//                       '&:hover': {
//                         backgroundColor: "#e8e8e8",
//                       },
//                     }
//                   }}
//                 >
//                   {industry}
//                 </ToggleButton>
//               ))}
//             </ToggleButtonGroup>
//           </Box>
//           <Grid container spacing={2}>
//             {selectedIndustries.map((industry) => (
//               <Grid item xs={12} md={6} key={industry}>
//                 <IndustryAnalysis industry={industry} />
//               </Grid>
//             ))}
//           </Grid>
//         </CardContent>
//       </Card>
//       <Card sx={{ mt: 4 }}>
//         <CardContent>
//           <Box display="flex" alignItems="center" justifyContent="space-between">
//             <Typography variant="h5" gutterBottom>
//               Recent Analysis Runs
//             </Typography>
//             <Link
//               component="button"
//               variant="h6"
//               onClick={handleViewAll}
//               style={{ color: "#5271ff", cursor: 'pointer'}}
//             >
//               View All Analysis Runs
//             </Link>
//           </Box>
//           <Grid container spacing={2}>
//             {recentJobs.length === 0 ? (
//               <Typography>No recent analysis runs found.</Typography>
//             ) : (
//               recentJobs.map((job) => (
//                 <Grid item xs={12} md={6} key={job.id}>
//                   <AnalysisRunCard 
//                     job={job} 
//                     onViewDetails={() => handleJobClick(job.id)} 
//                     onRerunAnalysis={() => handleRerunAnalysis(job.id)} 
//                   />
//                 </Grid>
//               ))
//             )}
//           </Grid>
//         </CardContent>
//       </Card>
//       <Card sx={{ mt: 4 }}>
//         <CardContent>
//           <Typography variant="h5" gutterBottom>
//             Key Upcoming Events
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={6}>
//               <Typography variant="h6" gutterBottom>
//                 Economic Calendar
//               </Typography>
//               {economicCalendarEvents.map(renderEventCard)}
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Typography variant="h6" gutterBottom>
//                 Geopolitical Summits
//               </Typography>
//               {geopoliticalSummits.map(renderEventCard)}
//             </Grid>
//           </Grid>
//         </CardContent>
//       </Card>
//     </DashboardLayout>
//   );
// };

// export default DashboardPage;
