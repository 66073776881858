/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext } from "react";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftAlert from "components/SoftAlert";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";
import Typography from "@mui/material/Typography";

// Images
import signupimg from "assets/images/curved-images/signupimg.jpg";

import { signInWithPopup, GoogleAuthProvider, getAuth} from "firebase/auth";
import { firebaseConfig } from "firebaseConfig";
import { AuthContext } from "auth.js";

function SignUp() {
  const [agreement, setAgremment] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleSetAgremment = () => setAgremment(!agreement);

  const googleProvider = new GoogleAuthProvider();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    setShowAlert(false);

    try {
      await firebaseConfig.auth().createUserWithEmailAndPassword(email, password);

    } catch (error) {
      // Handle the error in a user-friendly way without exposing sensitive details
      switch (error.code) {
        case 'auth/email-already-in-use':
          setAlertMessage('Email is already in use. Please choose another email.');
          setShowAlert(true); // Show alert
          break;
        case 'auth/weak-password':
          setAlertMessage('Password is too weak. Please choose a stronger password.');
          setShowAlert(true); // Show alert
          break;
        default:
          setAlertMessage('An unexpected error occurred. Please try again.');
          setShowAlert(true); // Show alert
      }

      console.error("An error occurred during the sign-up process:", error.code);
    }
};


  const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(getAuth(firebaseConfig), googleProvider);
        // Google Access Token.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;

        // Log the displayName of the user and email
        // console.log(user.displayName);
        // console.log(user.email);
    } catch (err) {
        console.error(err);
    }
  };

  const { currentUser, logOut } = useContext(AuthContext);


  if (currentUser) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <BasicLayout
      title={currentUser ? `Welcome, ${currentUser.displayName}!` : "Navigate sustainable investing today"}
      description="Sign up below"
      image={signupimg}>

      {showAlert && (
            <SoftAlert color="error" dismissible>
              {alertMessage}
            </SoftAlert>
          )}
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Sign up with
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={2} onClick={signInWithGoogle} >
          <Socials />
        </SoftBox>
        <Separator />
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" onSubmit={handleSubmit}>
            <SoftBox mb={2}>
              <SoftInput placeholder="Name" />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="email" placeholder="Email" name="email"/>
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="password" placeholder="Password" name="password" />
            </SoftBox>
            <SoftBox display="flex" alignItems="center">
              <Checkbox checked={agreement} onChange={handleSetAgremment} />
              <SoftTypography
                variant="button"
                fontWeight="regular"
                onClick={handleSetAgremment}
                sx={{ cursor: "poiner", userSelect: "none" }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </SoftTypography>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                textGradient
              >
                T n C
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton type="submit" variant="gradient" fullWidth style={{ backgroundColor: '#5271ff', color: 'white' }}>
                sign up
              </SoftButton>
            </SoftBox>

            <SoftBox mt={3} textAlign="center">
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Already have an account?&nbsp;
                <Typography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="#5271ff"
                  fontWeight="bold"
                >
                  Sign in
                </Typography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
