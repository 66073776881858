// src/examples/Sidenav/index.js

import React, { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import { useSoftUIController, setMiniSidenav } from "context";
import NavbarLogo from "../Navbars/DefaultNavbar/NavbarLogo";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const collapseName = location.pathname.split("/").slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch]);

  const renderRoutes = routes
    .filter((route) => route.name === "Dashboard" || route.name === "Analysis Runs")
    .sort((a, b) => {
      const order = ["Dashboard", "Analysis Runs"];
      return order.indexOf(a.name) - order.indexOf(b.name);
    })
    .map(({ type, name, icon, title, noCollapse, key, route, href }) => {
      let returnValue;

      if (type === "collapse") {
        returnValue = href ? (
          <a
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </a>
        ) : (
          <NavLink to={route} key={key}>
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <SoftTypography key={key} variant="caption" fontWeight="bold" textTransform="uppercase" opacity={0.6} pl={3} mt={2} mb={1} ml={1}>
            {title}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox pt={3} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
        >
          <Icon sx={{ fontWeight: "bold" }}>close</Icon>
        </SoftBox>
        <NavLink to="/dashboard"> {/* Link the logo to the dashboard */}
          <NavbarLogo isSidebar />
        </NavLink>
      </SoftBox>
      <Divider />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;


// import { useEffect } from "react";

// // react-router-dom components
// import { useLocation, NavLink } from "react-router-dom";

// // prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// // @mui material components
// import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
// import Link from "@mui/material/Link";
// import Icon from "@mui/material/Icon";

// // Soft UI Dashboard React components
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";

// // Soft UI Dashboard React examples
// import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
// import SidenavCard from "examples/Sidenav/SidenavCard";

// // Custom styles for the Sidenav
// import SidenavRoot from "examples/Sidenav/SidenavRoot";
// import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// // Soft UI Dashboard React context
// import { useSoftUIController, setMiniSidenav } from "context";

// function Sidenav({ color, brand, brandName, routes, ...rest }) {
//   const [controller, dispatch] = useSoftUIController();
//   const { miniSidenav, transparentSidenav } = controller;
//   const location = useLocation();
//   const { pathname } = location;
//   const collapseName = pathname.split("/").slice(1)[0];

//   const closeSidenav = () => setMiniSidenav(dispatch, true);

//   useEffect(() => {
//     // A function that sets the mini state of the sidenav.
//     function handleMiniSidenav() {
//       setMiniSidenav(dispatch, window.innerWidth < 1200);
//     }

//     /** 
//      The event listener that's calling the handleMiniSidenav function when resizing the window.
//     */
//     window.addEventListener("resize", handleMiniSidenav);

//     // Call the handleMiniSidenav function to set the state with the initial value.
//     handleMiniSidenav();

//     // Remove event listener on cleanup
//     return () => window.removeEventListener("resize", handleMiniSidenav);
//   }, [dispatch, location]);

//   // Render all the routes from the routes.js (All the visible items on the Sidenav)
//   const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {
//     let returnValue;

//     if (type === "collapse") {
//       returnValue = href ? (
//         <Link
//           href={href}
//           key={key}
//           target="_blank"
//           rel="noreferrer"
//           sx={{ textDecoration: "none" }}
//         >
//           <SidenavCollapse
//             color={color}
//             name={name}
//             icon={icon}
//             active={key === collapseName}
//             noCollapse={noCollapse}
//           />
//         </Link>
//       ) : (
//         <NavLink to={route} key={key}>
//           <SidenavCollapse
//             color={color}
//             key={key}
//             name={name}
//             icon={icon}
//             active={key === collapseName}
//             noCollapse={noCollapse}
//           />
//         </NavLink>
//       );
//     } else if (type === "title") {
//       returnValue = (
//         <SoftTypography
//           key={key}
//           display="block"
//           variant="caption"
//           fontWeight="bold"
//           textTransform="uppercase"
//           opacity={0.6}
//           pl={3}
//           mt={2}
//           mb={1}
//           ml={1}
//         >
//           {title}
//         </SoftTypography>
//       );
//     } else if (type === "divider") {
//       returnValue = <Divider key={key} />;
//     }

//     return returnValue;
//   });

//   return (
//     <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
//       <SoftBox pt={3} pb={1} px={4} textAlign="center">
//         <SoftBox
//           display={{ xs: "block", xl: "none" }}
//           position="absolute"
//           top={0}
//           right={0}
//           p={1.625}
//           onClick={closeSidenav}
//           sx={{ cursor: "pointer" }}
//         >
//           <SoftTypography variant="h6" color="secondary">
//             <Icon sx={{ fontWeight: "bold" }}>close</Icon>
//           </SoftTypography>
//         </SoftBox>
//         <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
//           {brand && <SoftBox component="img" src={brand} alt="AlgoSight Logo" width="9rem" />}
//           {/* <SoftBox
//             width={!brandName && "100%"}
//             sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
//           >
//             <SoftTypography component="h6" variant="button" fontWeight="medium">
//               {brandName}
//             </SoftTypography>
//           </SoftBox> */}
//         </SoftBox>
//       </SoftBox>
//       <Divider />
//       <List>{renderRoutes}</List>
//       {/* <SoftBox pt={2} my={2} mx={2} mt="auto">
//         <SidenavCard />
//         <SoftBox mt={2}>
//           <SoftButton
//             component="a"
//             href="https://creative-tim.com/product/soft-ui-dashboard-pro-react"
//             target="_blank"
//             rel="noreferrer"
//             variant="gradient"
//             color={color}
//             fullWidth
//           >
//             upgrade to pro
//           </SoftButton>
//         </SoftBox>
//       </SoftBox> */}
//     </SidenavRoot>
//   );
// }

// // Setting default values for the props of Sidenav
// Sidenav.defaultProps = {
//   color: "info",
//   brand: "",
// };

// // Typechecking props for the Sidenav
// Sidenav.propTypes = {
//   color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
//   brand: PropTypes.string,
//   brandName: PropTypes.string.isRequired,
//   routes: PropTypes.arrayOf(PropTypes.object).isRequired,
// };

// export default Sidenav;
