import React, { useState, useContext, useEffect} from 'react';
import { Button, TextField, Card, Checkbox, CardContent, Typography, Grid, FormControl, FormControlLabel, RadioGroup, Radio, Box, CircularProgress} from '@mui/material';
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "auth";
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { getFirestore, addDoc, collection, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';


const CreateJobForm = observer(() => {
  const [name, setName] = useState("");
  const [geopoliticalSentiment, setGeopoliticalSentiment] = useState(true);
  const [generatereport, setGenerateReport] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [investmentType, setInvestmentType] = useState(""); // default to 'real-estate'
  const [specificInvestment, setSpecificInvestment] = useState('');
  const [emailnotifs, setEmailNotifs] = useState(false);

  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const handleInvestmentTypeChange = (event) => {
    setInvestmentType(event.target.value);
    setSpecificInvestment('');
  };
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleEmailNotifsChange = (event) => {
    setEmailNotifs(event.target.value === "receive");
  };

  useEffect(() => {
    switch (investmentType) {
      case "real-estate":
        setSpecificInvestment('Dubai, U.A.E');
        break;
      case "commodities":
        setSpecificInvestment('Oil');
        break;
      case "forex":
        setSpecificInvestment('USD');
        break;
      case "equities":
        setSpecificInvestment('Apple');
        break;
      case "region":
        setSpecificInvestment('Asia');
        break;
      case "country":
        setSpecificInvestment('United States');
        break;
      default:
        setSpecificInvestment('');
    }
  }, [investmentType]);

  const investmentOptions = () => {
    switch (investmentType) {
      case "real-estate":
        return (
          <TextField
            fullWidth
            id="location"
            name="location"
            value={specificInvestment}
            onChange={(e) => setSpecificInvestment(e.target.value)}
            placeholder="e.g., Dubai, U.A.E"
          />
        );
      case "commodities":
        return (
          <TextField
            id="outlined-select-commodity"
            select
            value={specificInvestment}
            onChange={(e) => setSpecificInvestment(e.target.value)}
            helperText="Select desired commodity"
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option key="Oil" value="Oil"> Oil </option>
            <option key="Gold" value="Gold"> Gold </option>
            {/* <option key="Copper" value="Copper"> Copper </option>
            <option key="Silver" value="Silver"> Silver </option> */}
          </TextField>
        );
      case "forex":
        return (
          <TextField
            id="outlined-select-currency"
            select
            value={specificInvestment}
            onChange={(e) => setSpecificInvestment(e.target.value)}
            helperText="Select desired currency"
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option key="USD" value="USD"> $ - USD </option>
            <option key="GBP" value="GBP"> £ - GBP </option>
            <option key="EUR" value="EUR"> € - EUR </option>
            <option key="CNY" value="CNY"> ¥ - CNY </option>
          </TextField>
        );
      case "equities":
        return (
          <TextField
            select
            value={specificInvestment}
            onChange={(e) => setSpecificInvestment(e.target.value)}
            helperText="Select desired equity"
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option key="Microsoft" value="Microsoft"> Microsoft </option>
            <option key="Apple" value="Apple"> Apple </option>
            <option key="Google" value="Google"> Google </option>
            <option key="Uber" value="Uber"> Uber </option>
          </TextField>
        );
      case "region":
        return (
          <TextField
            id="outlined-select-region"
            select
            value={specificInvestment}
            onChange={(e) => setSpecificInvestment(e.target.value)}
            helperText="Select desired region"
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option key="Asia" value="Asia"> Asia </option>
            <option key="Middle East" value="Middle East"> Middle East </option>
            <option key="Europe" value="Europe"> Europe </option>
            <option key="North America" value="North America"> North America </option>
            <option key="South America" value="South America"> South America </option>
            <option key="Africa" value="Africa"> Africa </option>
            <option key="Oceania" value="Oceania"> Oceania </option>
          </TextField>
        );
      case "country":
        return (
          <TextField
            id="outlined-select-country"
            select
            value={specificInvestment}
            onChange={(e) => setSpecificInvestment(e.target.value)}
            helperText="Select desired country"
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option key="United States" value="United States"> United States </option>
            <option key="Saudi Arabia" value="Saudi Arabia"> Saudi Arabia </option>
            <option key="United Arab Emirates" value="United Arab Emirates"> United Arab Emirates </option>
            <option key="United Kingdom" value="United Kingdom"> United Kingdom </option>
            <option key="Canada" value="Canada"> Canada </option>
            <option key="Australia" value="Australia"> Australia </option>
            <option key="India" value="India"> India </option>
            <option key="China" value="China"> China </option>
          </TextField>
        );
      default:
        return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const currentTime = new Date().toISOString();

    if (!name.trim()) {
        alert("Please fill in and select all fields.");
        setIsSubmitting(false);
        return;
    }

    const jobData = {
        userId: currentUser.uid,
        input: {
            name,
            type: investmentType,
            specificType: specificInvestment,
            is_gen_report: generatereport,
            submissionTime: currentTime,
            is_geopolitical: geopoliticalSentiment,
        },
        notifications: {
            email: currentUser.email,
            emailnotifs,
            lastEmailSent: null // Initial value
        },
        result: {}, // empty result object since no analysis is done
    };

    try {
        const db = getFirestore();
        const jobRef = await addDoc(collection(db, "jobs"), jobData);

        // Update user metrics
        const userMetricsRef = doc(db, 'userMetrics', currentUser.uid);
        const userMetricsDoc = await getDoc(userMetricsRef);

        if (!userMetricsDoc.exists()) {
            // If userMetrics document does not exist, create it
            await setDoc(userMetricsRef, {
                logins: 0,
                analysisJobsCreated: {
                    equities: 0,
                    commodities: 0,
                    regions: 0,
                    countries: 0
                },
                industrySelections: {
                    Technology: 0,
                    Healthcare: 0,
                    Finance: 0,
                    Energy: 0,
                    ConsumerGoods: 0
                },
                assetsAnalyzed: {
                    equities: 0,
                    commodities: 0,
                    regions: 0,
                    countries: 0
                },
                industriesAnalysed: {
                  Technology: 0,
                  Healthcare: 0,
                  Finance: 0,
                  Energy: 0,
                  ConsumerGoods: 0
              }
            });
            console.log('User metrics document created.');

        }

        // Increment the appropriate analysisJobsCreated counter
        await updateDoc(userMetricsRef, {
            [`analysisJobsCreated.${investmentType}`]: increment(1),
            [`assetsAnalyzed.${investmentType}`]: increment(1)
        });

        console.log('User metrics updated.');
        


      const formData = new FormData();
      formData.append("name", name);
      formData.append("type", investmentType);
      formData.append("specificType", specificInvestment);
      formData.append("is_gen_report", generatereport);
      formData.append("is_geopolitical", geopoliticalSentiment);
      formData.append("submissionTime", currentTime);
      formData.append("userId", currentUser.uid);
      formData.append("jobId", jobRef.id); 
      formData.append("emailnotifs",emailnotifs);
              
      let functionsPayload = {
        email: currentUser.email,
        name: currentUser.email.substring(0, currentUser.email.indexOf('@'))
      };

      // Add functions payload to formData
      formData.append("functionsPayload", JSON.stringify(functionsPayload));

      const baseUrl = "https://algosight-app.com";
      const response = await fetch(`${baseUrl}/run-analysis`, {
        method: "POST",
        body: formData,
        credentials: 'include'
      });

      if (response.ok) {
        console.log("Files uploaded and analysis started successfully");
        navigate(`/job/${jobRef.id}`);
      } else {
        console.error("Upload failed");
        alert("job analysis upload failed.");
      }
    } catch (error) {
      console.error("Error submitting form or creating job in Firestore:", error);
      alert("An error occurred while submitting the form or creating the job.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isSubmitting ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
          <Typography variant="h6" marginLeft={2}>
            Running Analysis...
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={8} md={8} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Run a Geopolitical Impact Analysis
                </Typography>
                <SoftBox
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <Box mt={2}>
                    <Typography variant="subtitle1" gutterBottom>
                      Investment Reference Name
                      <Tooltip title="Enter a name for your reference">
                        <InfoIcon sx={{ ml: 1, verticalAlign: 'middle' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="e.g. Apple Research"
                    />
                  </Box>

                  <SoftBox mt={2}>
                    <Typography variant="subtitle1" gutterBottom>
                      Analysis Type
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        name="investmentType"
                        value={investmentType}
                        onChange={handleInvestmentTypeChange}
                      >
                        <FormControlLabel
                          value="equities"
                          control={<Radio />}
                          label="Equities"
                        />
                        <FormControlLabel
                          value="commodities"
                          control={<Radio />}
                          label="Commodities"
                        />
                        <FormControlLabel
                          value="region"
                          control={<Radio />}
                          label="Region"
                        />
                        <FormControlLabel
                          value="country"
                          control={<Radio />}
                          label="Country"
                        />
                      </RadioGroup>
                    </FormControl>
                  </SoftBox>

                  <SoftBox mt={2}>
                    {investmentOptions(investmentType)}
                  </SoftBox>


                  <SoftBox mt={2}>
                    <Box mb={4}>
                      <Typography variant="subtitle1" gutterBottom>
                        Analysis Options
                      </Typography>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={geopoliticalSentiment}
                            disabled // This line disables the checkbox
                          />
                        }
                        label="Geopolitical Analysis"
                      />


                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={generatereport}
                            onChange={(e) =>
                              setGenerateReport(e.target.checked)
                            }
                          />
                        }
                        label="Generate Analysis Report"
                      /> */}

                    </Box>
                  </SoftBox>

                  <SoftBox>
                    <FormControl component="fieldset">
                      <Typography variant="subtitle1" gutterBottom>
                        E-mail Notifications
                      </Typography>
                      <RadioGroup
                        row
                        name="emailnotifs"
                        value={emailnotifs ? 'receive' : "don't receive"}
                        onChange={handleEmailNotifsChange}
                      >
                        <FormControlLabel
                          value="receive"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="don't receive"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>

                  </SoftBox>

                  <SoftBox mt={2} display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      style={{ backgroundColor: "#5271ff", color: "white" }}
                    >
                      Run Analysis
                    </Button>
                  </SoftBox>
                </SoftBox>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
});

export default CreateJobForm;

// import React, { useState, useContext, useEffect} from 'react';
// import { Button, TextField, Card, Checkbox, CardContent, Typography, Grid, FormControl, FormControlLabel, RadioGroup, Radio, Box, CircularProgress} from '@mui/material';
// import SoftBox from "components/SoftBox";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import { observer } from 'mobx-react';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from "auth";
// import { getFirestore, addDoc, collection } from 'firebase/firestore';
// import Tooltip from '@mui/material/Tooltip';
// import InfoIcon from '@mui/icons-material/Info';

// const CreateJobForm = observer(() => {
//   const [name, setName] = useState("");
//   const [geopoliticalSentiment, setGeopoliticalSentiment] = useState(true);
//   const [generatereport, setGenerateReport] = useState(false);
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [investmentType, setInvestmentType] = useState(""); // default to 'real-estate'
//   const [specificInvestment, setSpecificInvestment] = useState('');
//   const [emailnotifs, setEmailNotifs] = useState(false);

//   const navigate = useNavigate();
//   const { currentUser } = useContext(AuthContext);

//   const handleInvestmentTypeChange = (event) => {
//     setInvestmentType(event.target.value);
//     setSpecificInvestment('');
//   };
//   const handleFileChange = (event) => {
//     setSelectedFiles(event.target.files);
//   };

//   const handleEmailNotifsChange = (event) => {
//     setEmailNotifs(event.target.value === "receive");
//   };

//   useEffect(() => {
//     switch (investmentType) {
//       case "real-estate":
//         setSpecificInvestment('Dubai, U.A.E');
//         break;
//       case "commodities":
//         setSpecificInvestment('Oil');
//         break;
//       case "forex":
//         setSpecificInvestment('USD');
//         break;
//       case "equities":
//         setSpecificInvestment('Apple');
//         break;
//       case "region":
//         setSpecificInvestment('Asia');
//         break;
//       case "country":
//         setSpecificInvestment('United States');
//         break;
//       default:
//         setSpecificInvestment('');
//     }
//   }, [investmentType]);

//   const investmentOptions = () => {
//     switch (investmentType) {
//       case "real-estate":
//         return (
//           <TextField
//             fullWidth
//             id="location"
//             name="location"
//             value={specificInvestment}
//             onChange={(e) => setSpecificInvestment(e.target.value)}
//             placeholder="e.g., Dubai, U.A.E"
//           />
//         );
//       case "commodities":
//         return (
//           <TextField
//             id="outlined-select-commodity"
//             select
//             value={specificInvestment}
//             onChange={(e) => setSpecificInvestment(e.target.value)}
//             helperText="Select desired commodity"
//             SelectProps={{
//               native: true,
//             }}
//             variant="outlined"
//           >
//             <option key="Oil" value="Oil"> Oil </option>
//             <option key="Gold" value="Gold"> Gold </option>
//             {/* <option key="Copper" value="Copper"> Copper </option>
//             <option key="Silver" value="Silver"> Silver </option> */}
//           </TextField>
//         );
//       case "forex":
//         return (
//           <TextField
//             id="outlined-select-currency"
//             select
//             value={specificInvestment}
//             onChange={(e) => setSpecificInvestment(e.target.value)}
//             helperText="Select desired currency"
//             SelectProps={{
//               native: true,
//             }}
//             variant="outlined"
//           >
//             <option key="USD" value="USD"> $ - USD </option>
//             <option key="GBP" value="GBP"> £ - GBP </option>
//             <option key="EUR" value="EUR"> € - EUR </option>
//             <option key="CNY" value="CNY"> ¥ - CNY </option>
//           </TextField>
//         );
//       case "equities":
//         return (
//           <TextField
//             select
//             value={specificInvestment}
//             onChange={(e) => setSpecificInvestment(e.target.value)}
//             helperText="Select desired equity"
//             SelectProps={{
//               native: true,
//             }}
//             variant="outlined"
//           >
//             <option key="Microsoft" value="Microsoft"> Microsoft </option>
//             <option key="Apple" value="Apple"> Apple </option>
//             <option key="Google" value="Google"> Google </option>
//             <option key="Uber" value="Uber"> Uber </option>
//           </TextField>
//         );
//       case "region":
//         return (
//           <TextField
//             id="outlined-select-region"
//             select
//             value={specificInvestment}
//             onChange={(e) => setSpecificInvestment(e.target.value)}
//             helperText="Select desired region"
//             SelectProps={{
//               native: true,
//             }}
//             variant="outlined"
//           >
//             <option key="Asia" value="Asia"> Asia </option>
//             <option key="Europe" value="Europe"> Europe </option>
//             <option key="North America" value="North America"> North America </option>
//             <option key="South America" value="South America"> South America </option>
//             <option key="Africa" value="Africa"> Africa </option>
//             <option key="Oceania" value="Oceania"> Oceania </option>
//           </TextField>
//         );
//       case "country":
//         return (
//           <TextField
//             id="outlined-select-country"
//             select
//             value={specificInvestment}
//             onChange={(e) => setSpecificInvestment(e.target.value)}
//             helperText="Select desired country"
//             SelectProps={{
//               native: true,
//             }}
//             variant="outlined"
//           >
//             <option key="United States" value="United States"> United States </option>
//             <option key="United Kingdom" value="United Kingdom"> United Kingdom </option>
//             <option key="Canada" value="Canada"> Canada </option>
//             <option key="Australia" value="Australia"> Australia </option>
//             <option key="India" value="India"> India </option>
//             <option key="China" value="China"> China </option>
//           </TextField>
//         );
//       default:
//         return null;
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setIsSubmitting(true);

//     const currentTime = new Date().toISOString();

//     if (!name.trim()) {
//       alert("Please fill in and select all fields.");
//       setIsSubmitting(false);
//       return;
//     }

//     const jobData = {
//       userId: currentUser.uid,
//       input: {
//         name,
//         type: investmentType,
//         specificType: specificInvestment,
//         is_gen_report: generatereport,
//         submissionTime: currentTime,
//         is_geopolitical: geopoliticalSentiment,
//       },
//       notifications: {
//         email: currentUser.email,
//         emailnotifs,
//         lastEmailSent: null // Initial value
//       },
//       result: {}, // empty result object since no analysis is done
//     };

//     try {
//       const db = getFirestore();
//       const jobRef = await addDoc(collection(db, "jobs"), jobData);

//       const formData = new FormData();
//       formData.append("name", name);
//       formData.append("type", investmentType);
//       formData.append("specificType", specificInvestment);
//       formData.append("is_gen_report", generatereport);
//       formData.append("is_geopolitical", geopoliticalSentiment);
//       formData.append("submissionTime", currentTime);
//       formData.append("userId", currentUser.uid);
//       formData.append("jobId", jobRef.id); 
//       formData.append("emailnotifs",emailnotifs);
              
//       let functionsPayload = {
//         email: currentUser.email,
//         name: currentUser.email.substring(0, currentUser.email.indexOf('@'))
//       };

//       // Add functions payload to formData
//       formData.append("functionsPayload", JSON.stringify(functionsPayload));


//       const baseUrl = "http://127.0.0.1:5000";
//       const response = await fetch(`${baseUrl}/run-analysis`, {
//         method: "POST",
//         body: formData,
//         credentials: 'include'
//       });

//       if (response.ok) {
//         console.log("Files uploaded and analysis started successfully");
//         navigate(`/job/${jobRef.id}`);
//       } else {
//         console.error("Upload failed");
//         alert("job analysis upload failed.");
//       }
//     } catch (error) {
//       console.error("Error submitting form or creating job in Firestore:", error);
//       alert("An error occurred while submitting the form or creating the job.");
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       {isSubmitting ? (
//         <Box
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="100vh"
//         >
//           <CircularProgress />
//           <Typography variant="h6" marginLeft={2}>
//             Submitting Job...
//           </Typography>
//         </Box>
//       ) : (
//         <Grid container spacing={3}>
//           <Grid item xs={8} md={8} lg={6}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h5" gutterBottom>
//                   Run a Geopolitical Impact Analysis
//                 </Typography>
//                 <SoftBox
//                   component="form"
//                   onSubmit={handleSubmit}
//                   noValidate
//                   sx={{ mt: 1 }}
//                 >
//                   <Box mt={2}>
//                     <Typography variant="subtitle1" gutterBottom>
//                       Investment Reference Name
//                       <Tooltip title="Enter a name for your reference">
//                         <InfoIcon sx={{ ml: 1, verticalAlign: 'middle' }} />
//                       </Tooltip>
//                     </Typography>
//                     <TextField
//                       fullWidth
//                       id="name"
//                       name="name"
//                       value={name}
//                       onChange={(e) => setName(e.target.value)}
//                       placeholder="e.g. Apple Research"
//                     />
//                   </Box>

//                   <SoftBox mt={2}>
//                     <Typography variant="subtitle1" gutterBottom>
//                       Analysis Type
//                     </Typography>
//                     <FormControl component="fieldset">
//                       <RadioGroup
//                         row
//                         name="investmentType"
//                         value={investmentType}
//                         onChange={handleInvestmentTypeChange}
//                       >
//                         <FormControlLabel
//                           value="equities"
//                           control={<Radio />}
//                           label="Equities"
//                         />
//                         <FormControlLabel
//                           value="commodities"
//                           control={<Radio />}
//                           label="Commodities"
//                         />
//                         <FormControlLabel
//                           value="region"
//                           control={<Radio />}
//                           label="Region"
//                         />
//                         <FormControlLabel
//                           value="country"
//                           control={<Radio />}
//                           label="Country"
//                         />
//                       </RadioGroup>
//                     </FormControl>
//                   </SoftBox>

//                   <SoftBox mt={2}>
//                     {investmentOptions(investmentType)}
//                   </SoftBox>


//                   <SoftBox mt={2}>
//                     <Box mb={4}>
//                       <Typography variant="subtitle1" gutterBottom>
//                         Analysis Options
//                       </Typography>

//                       <FormControlLabel
//                         control={
//                           <Checkbox
//                             checked={geopoliticalSentiment}
//                             onChange={(e) =>
//                               setGeopoliticalSentiment(e.target.checked)
//                             }
//                           />
//                         }
//                         label="Geopolitical Analysis"
//                       />

//                       <FormControlLabel
//                         control={
//                           <Checkbox
//                             checked={generatereport}
//                             onChange={(e) =>
//                               setGenerateReport(e.target.checked)
//                             }
//                           />
//                         }
//                         label="Generate Analysis Report"
//                       />

//                     </Box>
//                   </SoftBox>

//                   <SoftBox>
//                     <FormControl component="fieldset">
//                       <Typography variant="subtitle1" gutterBottom>
//                         E-mail Notifications
//                       </Typography>
//                       <RadioGroup
//                         row
//                         name="emailnotifs"
//                         value={emailnotifs ? 'receive' : "don't receive"}
//                         onChange={handleEmailNotifsChange}
//                       >
//                         <FormControlLabel
//                           value="receive"
//                           control={<Radio />}
//                           label="Yes"
//                         />
//                         <FormControlLabel
//                           value="don't receive"
//                           control={<Radio />}
//                           label="No"
//                         />
//                       </RadioGroup>
//                     </FormControl>

//                   </SoftBox>

//                   {/* <SoftBox mt={2}>
//                     <Typography variant="subtitle1">Upload Files</Typography>
//                     <Typography variant="body2" sx={{ mt: 1 }}>
//                       Optionally upload your analysis documents and/or data
//                       sources below for a more tailored analysis.
//                     </Typography>
//                     <input
//                       type="file"
//                       multiple
//                       onChange={handleFileChange}
//                       disabled
//                       style={{ opacity: 0.5 }}
//                     /> */}
//                   {/* </SoftBox> */}
//                   <SoftBox mt={2} display="flex" justifyContent="flex-end">
//                     <Button
//                       type="submit"
//                       variant="contained"
//                       sx={{ mt: 3, mb: 2 }}
//                       style={{ backgroundColor: "#5271ff", color: "white" }}
//                     >
//                       Run Analysis
//                     </Button>
//                   </SoftBox>
//                 </SoftBox>
//               </CardContent>
//             </Card>
//           </Grid>
//         </Grid>
//       )}
//     </DashboardLayout>
//   );
// });

// export default CreateJobForm;
