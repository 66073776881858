import { makeAutoObservable, runInAction } from 'mobx';
import { getFirestore, collection, addDoc, getDocs, query, where , deleteDoc, doc } from 'firebase/firestore';
import { firebaseConfig } from 'firebaseConfig';

class JobsStore {
  jobs = [];

  constructor() {
    makeAutoObservable(this);
    this.db = getFirestore(firebaseConfig);
  }

  async addJob(userId, jobData) {
    const jobWithUser = { ...jobData, userId, createdAt: new Date() };
    await addDoc(collection(this.db, "jobs"), jobWithUser);
    this.fetchJobs(userId);  // Refresh the jobs list after adding a new one
  }

  async fetchJobs(userId) {
    const q = query(collection(this.db, "jobs"), where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    runInAction(() => {
      this.jobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    });
  }

  async deleteJob(userId, jobId) {
    await deleteDoc(doc(this.db, "jobs", jobId));
    if (userId) {
      this.fetchJobs(userId);
    }
  }
}

const jobsStore = new JobsStore();
export default jobsStore;
