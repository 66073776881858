import React, { useState } from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import RiskAnalysisJobsList from "../RiskAnalysisJobsList";
import CreateJobButton from "../CreateJobButton";

function Dashboard() {
  const [isSorted, setIsSorted] = useState(false);

  const handleSort = () => {
    setIsSorted(!isSorted);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Grid container justifyContent="space-between" alignItems="flex-end" spacing={2}>
          <Grid item>
            <Typography variant="h4" gutterBottom>Analysis Runs</Typography>
          </Grid>
          <Grid item>
            <Box display="flex" alignItems="center" gap={2}>
              <Button variant="text" color="primary" onClick={handleSort} style={{ color: '#5271ff' }}>
                {isSorted ? 'Sort by Oldest' : 'Sort by Newest'}
              </Button>
              <CreateJobButton />
            </Box>
          </Grid>
        </Grid>
        <SoftBox mt={2}>
          <RiskAnalysisJobsList isSorted={isSorted} />
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Dashboard;