/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Overview page components
import Header from "layouts/profile/components/Header";

function Overview() {
  return (
    <DashboardLayout>
      <SoftBox mb={3}>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
            </SoftBox>
        <SoftTypography component="h1" variant="h1" style={{ marginTop: '55px', fontSize: '18px' }}>
          Your Profile
        </SoftTypography>
            <SoftBox mb={1}>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>
          </SoftBox>
      </SoftBox>

    </DashboardLayout>
  );
}

export default Overview;
