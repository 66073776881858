import React from "react";
import { WorldMap } from "react-svg-worldmap";

function GeopoliticalVisualization({ mappingData }) {
  // Tooltip text function to show description on hover
  const tooltipTextFunction = (context) => {
    const countryInfo = mappingData.find(d => d.country === context.countryCode);
    return countryInfo ? `${context.countryName}: ${countryInfo.value}` : `${context.countryName}: No data available`;
  };

  // Styling function to set colors and styles for the map
  const stylingFunction = (context) => {
    const countryInfo = mappingData.find(d => d.country === context.countryCode);
    return {
      fill: countryInfo ? countryInfo.color || "#5271ff" : "#E0E0E0", // Use the color from the data, default to purple if not available, grey if no data
      stroke: "black",
      strokeWidth: 0.5,
      strokeOpacity: 0.5,
      cursor: "pointer",
    };
  };

  return (
    <div>
      <WorldMap
        size="responsive"
        data={mappingData.map(({ country, value }) => ({
          country: country,
          value: value ? 1 : 0 // Use 1 if there is value, 0 otherwise to leverage coloring
        }))}
        color="red" // This default color is overridden by stylingFunction
        styleFunction={stylingFunction}
        tooltipTextFunction={tooltipTextFunction}
      />
    </div>
  );
}

export default GeopoliticalVisualization;



// import React from "react";
// import { WorldMap } from "react-svg-worldmap";

// function GeopoliticalVisualization({ mappingData }) {
//   // Tooltip text function to show description on hover
//   const tooltipTextFunction = (context) => {
//     const countryInfo = mappingData.find(d => d.country === context.countryCode);
//     return countryInfo ? `${context.countryName}: ${countryInfo.value}` : `${context.countryName}: No data available`;
//   };

//   // Styling function to set colors and styles for the map
//   const stylingFunction = ({
//     countryValue,
//     minValue,
//     maxValue,
//     country,
//     color,
//   }) => {
//     return {
//       fill: countryValue ? "#5271ff" : "#E0E0E0", // Gold for countries with data, grey for those without
//       stroke: "black",
//       strokeWidth: 0.5,
//       strokeOpacity: 0.5,
//       cursor: "pointer",
//     };
//   };

//   return (
//     <div>
//       <WorldMap
//         size="responsive"
//         data={mappingData.map(({ country, value }) => ({
//           country: country,
//           value: value ? 1 : 0 // Use 1 if there is value, 0 otherwise to leverage coloring
//         }))}
//         color="red"
//         styleFunction={stylingFunction}
//         tooltipTextFunction={tooltipTextFunction}
//       />
//     </div>
//   );
// }

// export default GeopoliticalVisualization;
