import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { collection, getFirestore, query, where, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { List, ListItem, ListItemText, Typography, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'auth';
import { parseISO, format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';

const getInvestmentTypeColor = (type) => {
  switch (type) {
    case 'equities':
      return '#00bcd4'; // cyan
    case 'commodities':
      return '#ff9800'; // orange
    case 'region':
      return '#4caf50'; // green
    case 'country':
      return '#9c27b0'; // purple
    default:
      return '#607d8b'; // default color
  }
};

const Badge = styled(Box)(({ color }) => ({
  display: 'inline-block',
  padding: '2px 4px', // Reduced padding
  borderRadius: '4px',
  backgroundColor: color,
  color: 'white',
  fontWeight: 'bold',
  fontSize: '0.8rem', // Smaller font size
  marginLeft: '12px', // Add spacing between badges
}));

const RiskAnalysisJobsList = observer(({ isSorted }) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    if (currentUser?.uid) {
      const db = getFirestore();
      const jobsRef = collection(db, "jobs");
      const q = query(jobsRef, where("userId", "==", currentUser.uid));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const jobsArray = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data().input,
          processed_at: doc.data().input.submissionTime,
          lastRun: doc.data().lastRun  // Assuming there is a 'lastRun' field in your data
        }));
        setJobs(jobsArray);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const sortedJobs = isSorted
    ? [...jobs].sort((a, b) => parseISO(b.processed_at) - parseISO(a.processed_at))
    : [...jobs].sort((a, b) => parseISO(a.processed_at) - parseISO(b.processed_at));

  if (sortedJobs.length === 0) {
    return <Typography>No analysis runs found.</Typography>;
  }

  const handleJobClick = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  const handleDeleteJob = async (jobId) => {
    if (currentUser?.uid) {
      const db = getFirestore();
      const jobRef = doc(db, 'jobs', jobId);
      await deleteDoc(jobRef);
      setJobs((prevJobs) => prevJobs.filter((job) => job.id !== jobId));
    }
  };

  return (
    <List>
      {sortedJobs.map((job) => (
        <ListItem key={job.id} divider style={{ cursor: 'pointer' }} sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 123, 255, 0.1)', // subtle blue background
          },
        }}>
          <ListItemText
            primary={(
              <Box onClick={() => handleJobClick(job.id)}>
                {job.name}
                {job.specific_investment && (
                  <Badge color="#5271ff">
                    {job.specific_investment}
                  </Badge>
                )}
                {job.type && (
                  <Badge color={getInvestmentTypeColor(job.type)}>
                    {job.type}
                  </Badge>
                )}
              </Box>
            )}
            secondary={(
              <Box>
                <Typography variant="body2" color="textSecondary">
                  Last Run: {job.lastRun ? format(parseISO(job.lastRun), 'PPPpp') : 'N/A'}
                </Typography>
                {/* <Typography variant="body2" color="textSecondary">
                  Submitted At: {job.processed_at ? format(parseISO(job.processed_at), 'PPPpp') : 'N/A'}
                </Typography> */}
              </Box>
            )}
          />
          <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteJob(job.id)} sx={{
              '&:hover': {
                color: '#007bff', // blue color on hover
              },
            }}>
            <DeleteIcon />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
});

export default RiskAnalysisJobsList;
