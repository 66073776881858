import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import SoftBox from "components/SoftBox";

function NavbarLogo({ transparent, light, isSidebar }) {
  const fontSize = isSidebar ? "subtitle3" : "subtitle3"; // Larger size for sidebar

  return (
    <SoftBox component={Link} to="/" py={transparent ? 1.5 : 0.75} lineHeight={1}>
      <Typography variant={fontSize} fontWeight="bold" style={{ color: light ? "white" : "black" }}>
        Algo
      </Typography>
      <Typography variant={fontSize} fontWeight="bold" style={{ color: "#5271ff" }}>
        Sight
      </Typography>
    </SoftBox>
  );
}

export default NavbarLogo;
