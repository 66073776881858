import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Box, FormControlLabel, Switch } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const convertToLocaleDateString = (label) => {
  const [day, month, year] = label.split('/');
  return new Date(`${year}-${month}-${day}`).toLocaleDateString('en-US');
};

const randomColor = () => Math.floor(Math.random() * 255);

const StockChart = (props) => {

  const initialStock = props.specific_investment;
  const showAdditionalStocks = props.showCompAnalysis;
  const initialLabel = props.labels;
  const initialData = props.labelData;
  const initalSummaries = props.labelSummary

  const [data, setData] = useState({ labels: [], datasets: [] });
  const [visibleDatasets, setVisibleDatasets] = useState({ [initialStock]: true });
  const [events, setEvents] = useState([]);

  const fetchAdditionalStocks = async (labels) => {
    try {
      console.log("Fetching additional stocks...");

      const response = await fetch('https://algosight-app.com/compstocks', {
        method: 'POST',
        body: JSON.stringify({ 'initialStock': initialStock }),
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);

      if (result.status !== 'success') {
        throw new Error('Failed to fetch stock data');
      }

      const outputData = result.data.map(item => ({
        name: item.name,
        data: item.data,
        visible: true
      }));

      const additionalDatasets = outputData.map(stock => ({
        label: stock.name,
        data: stock.data,
        borderColor: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
        backgroundColor: `rgba(${randomColor()}, ${randomColor()}, ${randomColor()}, 0.5)`,
        pointRadius: 3,
        pointBackgroundColor: 'rgba(0, 0, 0, 0.5)',
        visible: stock.visible
      }));

      console.log("Final", additionalDatasets)
      setData(prevData => ({
        labels: labels,  // Assuming all stocks have the same labels
        datasets: [...prevData.datasets, ...additionalDatasets]
      }));

      setVisibleDatasets(prev => ({
        ...prev,
        ...additionalDatasets.reduce((acc, stock) => ({ ...acc, [stock.label]: stock.visible }), {})
      }));

    } catch (error) {
      console.error('Failed to fetch additional stocks:', error);
      // Optionally update state to reflect error
    }
  };


  useEffect(() => {
    const labels = initialLabel.map(convertToLocaleDateString);

    const stockEvents = [];

    for (let i = 0; i < initalSummaries.length; i++) {
      stockEvents.push({
        stock: initialStock,
        index: i,
        text: initalSummaries[i],
        style: {
          pointRadius: 6,
          pointStyle: 'rectRounded',
          pointBackgroundColor: 'blue'  // Example color, change as needed
        }
      });
    }

    setEvents(stockEvents);

    const initialDataset = {
      label: initialStock,
      data: initialData,
      borderColor: `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`,
      backgroundColor: `rgba(${randomColor()}, ${randomColor()}, ${randomColor()}, 0.5)`,
      pointRadius: labels.map((label, index) => stockEvents.find(e => e.index === index && e.stock === initialStock)?.style.pointRadius || 3),
      pointStyle: labels.map((label, index) => stockEvents.find(e => e.index === index && e.stock === initialStock)?.style.pointStyle || 'circle'),
      pointBackgroundColor: labels.map((label, index) => stockEvents.find(e => e.index === index && e.stock === initialStock)?.style.pointBackgroundColor || 'rgba(0, 0, 0, 0.5)'),
    };

    setData({ labels, datasets: [initialDataset] });

    const fetchStocks = async () => {
      if (showAdditionalStocks) {
        await fetchAdditionalStocks(labels);
      }
      else if(!showAdditionalStocks){
        setData({ labels, datasets: [initialDataset] });
      }
    };

    fetchStocks();
  }, [initialStock, initialLabel, initialData, showAdditionalStocks]);

  const toggleDatasetVisibility = (datasetKey) => {
    setVisibleDatasets(prevState => ({
      ...prevState,
      [datasetKey]: !prevState[datasetKey]
    }));
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          filter: (legendItem, chartData) => visibleDatasets[chartData.datasets[legendItem.datasetIndex].label]
        }
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label;
            const dataset = tooltipItem.dataset.label;
            const event = events.find(e => e.index === tooltipItem.dataIndex && e.stock === dataset);
            return event 
            ? [` ${label}`, `GSI: ${tooltipItem.raw.toFixed(2)}`, `${event.text}`]
            : [`${label}`, `${tooltipItem.raw.toFixed(2)}`];
          }
        }
      }
    },
    animation: {
      duration: 2000,
      easing: 'easeOutQuad',
    }
  };

  return (
    <div>
      <Line data={{ labels: data.labels, datasets: data.datasets.filter(dataset => visibleDatasets[dataset.label]) }} options={options} />
      <Box display="flex" justifyContent="center" m={2}>
        {data.datasets.map(dataset => (
          <FormControlLabel
            key={dataset.label}
            control={
              <Switch
                checked={!!visibleDatasets[dataset.label]}
                onChange={() => toggleDatasetVisibility(dataset.label)}
                name={dataset.label}
                color="warning"
              />
            }
            label={dataset.label}
          />
        ))}
      </Box>
    </div>
  );
}

export default StockChart;
