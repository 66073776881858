import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreateJobButton = () => {
  const navigate = useNavigate();

  const handleCreateNewJob = () => {
    // Navigate to the job creation form or page
    // Update this with your actual route
    navigate('/create-job');
  };

  return (
    <Button variant="contained" onClick={handleCreateNewJob} style={{ backgroundColor: '#5271ff', color: 'white' }}>
      Run New Analysis
    </Button>
  );
};

export default CreateJobButton;

