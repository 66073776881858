import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { CircularProgress } from '@mui/material';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { firebaseConfig } from 'firebaseConfig';
import { AuthContext } from "auth";
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Card, Checkbox, CardContent, Typography, Grid, FormControl, FormControlLabel, RadioGroup, Radio, Box, Select, MenuItem } from '@mui/material';
import SoftBox from "components/SoftBox";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import customizationStore from './customizationStore';

const Customization = observer(() => {
  const [companyName, setCompanyName] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [individualName, setIndividualName] = useState('');
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isIndividual, setIsIndividual] = useState('Individual');
  const [equities, setEquities] = useState(false)
  const [forex, setForex] = useState(false)
  const [realestate, setRealEstate] = useState(false)
  const [commodities, setCommodities] = useState(false)
  const [howDidYouHear, setHowDidYouHear] = useState('');
  const [otherInfo, setOtherInfo] = useState('');
  
  useEffect(() => {
    if (currentUser) {
      customizationStore.setUserId(currentUser.uid); // Set user ID here

      const db = getFirestore(firebaseConfig);
      const docRef = doc(db, "users", currentUser.uid);
      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("companyName")) {
          navigate("/dashboard");
        } else {
          setLoading(false);
        }
      });
    } else {
      navigate("/sign-in");
    }
  }, [currentUser, navigate]);

  const handleUserTypeChange = (event) => {
    setIsIndividual(event.target.value);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handleIndividualNameChange = (e) => {
    setIndividualName(e.target.value);
  };

  const handleSelectIndustry = (industry) => {
    setIndustryName(industry);
  };

  const handleHowDidYouHearChange = (event) => {
    setHowDidYouHear(event.target.value);
  };
  

  const handleOtherInfoChange = (event) => {
    setOtherInfo(event.target.value);
  };

  const handleSubmitCompany = async () => {
    if (
      !companyName.trim() ||
      !industryName ||
      !howDidYouHear || 
      (howDidYouHear === "Other" && !otherInfo.trim()) ||
      howDidYouHear === "" // Check if an option is selected
    ) {
      alert("Please fill in all fields.");
      return;
    }

    customizationStore.setCompanyName(companyName);
    customizationStore.setIndustryName(industryName);

    try {
      await customizationStore.submitCustomizationData();
      navigate("/dashboard");
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("An error occurred while submitting your data.");
    }
  };

  const handleSubmitIndividual = async () => {
    if (
      !individualName.trim() ||
      (!equities && !forex && !realestate && !commodities) ||
      !howDidYouHear ||
      (howDidYouHear === "Other" && !otherInfo.trim()) ||
      howDidYouHear === "" // Check if an option is selected
    ) {
      alert("Please fill in all required fields.");
      return;
      }
  
    customizationStore.setIndividualName(individualName);
    customizationStore.setInvestments({
      equities,
      forex,
      realestate,
      commodities
    });
  
    try {
      await customizationStore.submitIndividualData();
      navigate("/dashboard"); // or another appropriate action
    } catch (error) {
      console.error("Error submitting individual data:", error);
      alert("An error occurred while submitting your individual data.");
    }
    
    customizationStore.setIndividualName(individualName);
    customizationStore.setInvestments({
      equities,
      forex,
      realestate,
      commodities
    });
  
    try {
      await customizationStore.submitIndividualData();
      navigate("/dashboard"); // or another appropriate action
    } catch (error) {
      console.error("Error submitting individual data:", error);
      alert("An error occurred while submitting your individual data.");
    }
  };

  


  if (loading) {
    return <CircularProgress />;
  }

  return (
    <CoverLayout title="Set Up Your Company Profile">
      <SoftBox mt={2}>
        <SoftTypography component="h6" variant="h6" style={{ marginBottom: '5px', fontSize: '18px' }}>
          Are you an Individual or a Business?
        </SoftTypography>
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <RadioGroup
            row
            name="userTypeSelect"
            value={isIndividual}
            onChange={handleUserTypeChange}
          >
            <FormControlLabel
              value="Individual"
              control={<Radio />}
              label="Individual"
              style={{ marginLeft: '5px', marginRight: '90px' }}
            />
            <FormControlLabel
              value="Business"
              control={<Radio />}
              label="Business"
            />
          </RadioGroup>
        </FormControl>
      </SoftBox>
      {isIndividual === "Individual" && ( // If Individual
        <Box>
          <SoftTypography component="h6" variant="h6" style={{ marginBottom: '-15px', fontSize: '18px' }}>
            Enter your name
          </SoftTypography>
          <SoftBox mt={4} mb={1}>
            <SoftInput
              placeholder="Name"
              value={individualName}
              onChange={handleIndividualNameChange}
              style={{ marginBottom: '20px' }}
            />
            <SoftTypography component="h6" variant="h6" style={{ marginBottom: '20px', fontSize: '18px' }}>
              What asset classes do you invest in? Select all that apply.
            </SoftTypography>

            <SoftBox mt={2}>
                    <Box mb={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={realestate}
                            onChange={(e) =>
                              setRealEstate(e.target.checked)
                            }
                          />
                        }
                        label="Real Estate"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={equities}
                            onChange={(e) =>
                              setEquities(e.target.checked)
                            }
                          />
                        }
                        label="Equities"
                      />
                    <FormControlLabel
                        control={
                          <Checkbox
                            checked={forex}
                            onChange={(e) =>
                              setForex(e.target.checked)
                            }
                          />
                        }
                        label="Forex"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={commodities}
                            onChange={(e) =>
                              setCommodities(e.target.checked)
                            }
                          />
                        }
                        label="Commodities"
                      />
                    </Box>
                  </SoftBox>
                  <SoftTypography component="h6" variant="h6" style={{ marginBottom: '-15px', fontSize: '18px' }}>
                How did you hear about us?
              </SoftTypography>
              <SoftBox mt={4} mb={1} style={{ overflow: 'hidden' }}>
                <TextField
                  id="outlined-select-commodity"
                  select
                  value={howDidYouHear}
                  onChange={handleHowDidYouHearChange}
                  helperText="Please specify (Mandatory)"
                  variant="outlined"
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    width: '50%',
                  }}
                >
                  <option value="">Select an option</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Company Website">Company Website</option>
                  <option value="Beta Launch Invitation">Beta Launch Invitation</option>
                  <option value="Other">Other</option>
                </TextField>
              </SoftBox>


              {howDidYouHear === "Other" && (
                <SoftBox mt={4} mb={1}>
                <SoftInput
                  placeholder="Please specify (Mandatory)"
                  value={otherInfo}
                  onChange={handleOtherInfoChange}
                  style={{ marginBottom: '20px', width: '100%' }}
                />
                </SoftBox>)}
            <SoftButton onClick={handleSubmitIndividual} style={{ marginLeft: '-5px', marginTop: '5px' }}>
              Submit
            </SoftButton>
          </SoftBox> 
              </Box>
      )}


      {isIndividual=== "Business" && ( // if Business
        <Box>
          <SoftTypography component="h6" variant="h6" style={{ marginBottom: '5px', fontSize: '18px' }}>
            Enter your company name
          </SoftTypography>
          <SoftBox mt={4} mb={1}>
            <SoftInput
              placeholder="Company Name"
              value={companyName}
              onChange={handleCompanyNameChange}
              style={{ marginBottom: '20px' }}
            />
            <SoftTypography component="h6" variant="h6" style={{ marginBottom: '20px', fontSize: '18px' }}>
              Select your industry
            </SoftTypography>
            <SoftBox style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '18px' }}>
              <SoftButton
                onClick={() => handleSelectIndustry("Asset Management")}
                style={{ width: '50%', height: '60px', margin: '10px' }}
                color={industryName === "Asset Management" ? "primary" : "info"}
              >
                Asset Management
              </SoftButton>
              <SoftButton
                onClick={() => handleSelectIndustry("Commercial Bank")}
                style={{ width: '50%', height: '60px', margin: '10px' }}
                color={industryName === "Commercial Bank" ? "primary" : "info"}
              >
                Commercial Bank
              </SoftButton>
              <SoftButton
                onClick={() => handleSelectIndustry("Real Estate")}
                style={{ width: '50%', height: '60px', margin: '10px' }}
                color={industryName === "Real Estate" ? "primary" : "info"}
              >
                Real Estate
              </SoftButton>
            </SoftBox>
            <SoftBox style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <SoftButton
                onClick={() => handleSelectIndustry("Commodity Investment")}
                style={{ width: '50%', height: '60px', margin: '10px' }}
                color={industryName === "Commodity Investment" ? "primary" : "info"}
              >
                Commodity Investment
              </SoftButton>
              <SoftButton
                onClick={() => handleSelectIndustry("Family Office")}
                style={{ width: '50%', height: '60px', margin: '10px' }}
                color={industryName === "Family Office" ? "primary" : "info"}
              >
                Family Office
              </SoftButton>
              <SoftButton
                onClick={() => handleSelectIndustry("Private Equity")}
                style={{ width: '50%', height: '60px', margin: '10px' }}
                color={industryName === "Private Equity" ? "primary" : "info"}
              >
                Private Equity
              </SoftButton>
            </SoftBox>
            <SoftTypography component="h6" variant="h6" style={{ marginBottom: '-15px', fontSize: '18px' }}>
                How did you hear about us?
              </SoftTypography>
              <SoftBox mt={4} mb={1} style={{ overflow: 'hidden' }}>
                <TextField
                  id="outlined-select-commodity"
                  select
                  value={howDidYouHear}
                  onChange={handleHowDidYouHearChange}
                  helperText="Please specify (Mandatory)"
                  variant="outlined"
                  SelectProps={{
                    native: true,
                  }}
                  style={{
                    width: '50%',
                  }}
                >
                  <option value="">Select an option</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Company Website">Company Website</option>
                  <option value="Beta Launch Invitation">Beta Launch Invitation</option>
                  <option value="Other">Other</option>
                </TextField>
              </SoftBox>

              {howDidYouHear === "Other" && (
                <SoftBox mt={4} mb={1}>
                <SoftInput
                  placeholder="Please specify (Mandatory)"
                  value={otherInfo}
                  onChange={handleOtherInfoChange}
                  style={{ marginBottom: '20px', width: '100%' }}
                />
                </SoftBox>)}
            <SoftButton onClick={handleSubmitCompany} style={{ marginLeft: '-5px', marginTop: '5px' }}>
              Submit
            </SoftButton>
          </SoftBox>
          
        </Box>
      )}
    </CoverLayout>
  );
});

export default Customization;
