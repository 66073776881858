import { observable, action, makeObservable } from 'mobx';
import firebaseService from '../firebaseService'; // Assumed service for Firebase interactions

class CustomizationStore {
  userId = observable.box('');
  companyName = observable.box('');
  industryName = observable.box('');
  individualName = observable.box('');
  investments = observable.map();

  constructor() {
    makeObservable(this, {
      companyName: observable,
      industryName: observable,
      individualName: observable,
      investments: observable,
      setCompanyName: action,
      setIndustryName: action,
      setIndividualName: action,
      setInvestments: action,
      submitCustomizationData: action,
      submitIndividualData: action,
    });
  }

  setUserId(id) {
    this.userId.set(id);
  }

  setCompanyName(name) {
    this.companyName.set(name);
  }

  setIndustryName(industry) {
    this.industryName.set(industry);
  }

  setIndividualName(name) {
    this.individualName.set(name);
  }

  setInvestments(investments) {
    this.investments.replace(investments);
  }

  async submitCustomizationData() {
    try {
        const userId = this.userId.get();
        if (!userId) throw new Error('User ID is not set.');

        const customizationData = {
            companyName: this.companyName.get(),
            industryName: this.industryName.get(),
        };

        await firebaseService.updateUserData(userId, customizationData);
        console.log('Customization data updated in Firestore');
    } catch (error) {
        console.error('Error updating customization data:', error);
        throw error;
    }
  }

  async submitIndividualData() {
    try {
        const userId = this.userId.get();
        if (!userId) throw new Error('User ID is not set.');

        const individualData = {
            individualName: this.individualName.get(),
            investments: Object.fromEntries(this.investments.entries()),
        };

        await firebaseService.updateUserData(userId, individualData);
        console.log('Individual data updated in Firestore');
    } catch (error) {
        console.error('Error updating individual data:', error);
        throw error;
    }
  }
}

const customizationStore = new CustomizationStore();
export default customizationStore;
