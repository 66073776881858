// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyA88rueJdWTJMpcKwFyc00mg9LSvRrQoAA",
    authDomain: "algosight-frontend.firebaseapp.com",
    databaseURL: "https://algosight-frontend-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "algosight-frontend",
    storageBucket: "algosight-frontend.appspot.com",
    messagingSenderId: "372568619041",
    appId: "1:372568619041:web:94a4d453d090fa34020b60",
    measurementId: "G-7BV05T024E"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const firestore = getFirestore(app);

export { firestore };
