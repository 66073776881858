import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';
import { Typography, Box } from '@mui/material';
import 'chartjs-plugin-datalabels';  

// Function to calculate the background color based on the score
const getScoreColor = (score) => {
    
  let red, green, blue, alpha;

  if (score > 0) {
    // Bright green as score approaches 1
    red = 0;
    green = 255;
    blue = 0;
    alpha = score - (score * 0.4);

  } else if (score < 0) {
    // Bright red as score approaches -1
    red = 245;
    green = 0;
    blue = 0;
    alpha = Math.abs(score) + (score * 0.4);

  } else {
    // Fully transparent at score 0
    red = 200;
    green = 200;
    blue = 200;
    alpha = 1;
  }

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

// Styled component for displaying the score
const ScoreBox = styled(Box)(({ score }) => ({
  background: getScoreColor(score),
  color: '#000',
  padding: '4px 8px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '50px',
  maxWidth: '80px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  marginTop: '10px',
  marginLeft: 'auto', // Ensures alignment in case of any flexbox discrepancies
  marginRight: 'auto'
}));



const ESGVisualization = ({ esgData }) => {
  // Extract scores from the example data
  const environmentalScore = esgData.esgMapping.Environmental.score;
  const socialScore = esgData.esgMapping.Social.score;
  const governanceScore = esgData.esgMapping.Governance.score;

  // Normalize the score to be between 0 and 1
  const normalizeScore = (score) => (score + 1) / 2;

  const normalizedEnvironmentalScore = normalizeScore(environmentalScore);
  const normalizedSocialScore = normalizeScore(socialScore);
  const normalizedGovernanceScore = normalizeScore(governanceScore);

  const createChartData = (normalizedScore) => ({
    datasets: [
      {
        data: [normalizedScore, 1 - normalizedScore],
        backgroundColor: ['rgba(144, 238, 144, 0.8)', 'rgba(211, 211, 211, 0.8)'], // Pastel green and pastel grey
        borderColor: 'transparent',
      }
    ],
  });


  // Chart options with center labels displaying the actual score
  const options = {
    maintainAspectRatio: false,
    cutout: '70%',  // Adjusted for better visibility of the center label
    plugins: {
      datalabels: {
        color: '#000000',
        anchor: 'center',
        align: 'center',
        font: {
          weight: 'bold',
          size: 16,
        },
        formatter: (value, ctx) => {
          if (ctx.dataIndex === 0) {  // Only show label for the filled area
            return value.toFixed(2);  // Display the actual score, formatted to 2 decimal places
          }
          return null;
        },
        backgroundColor: '#ffffff',  // White background for the label box
        borderRadius: 4,  // Rounded corners for the label box
        padding: 6  // Padding inside the label box
      },
      tooltip: {
        enabled: false,  // Disable tooltips
      },
      legend: {
        display: false,  // No legend
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', height: '32vh' }}>
      <Box textAlign="center">
        <h3>Environment</h3>
        <div style={{ height: '150px', width: '150px' }}>
          <Doughnut data={createChartData(normalizedEnvironmentalScore)} options={options} />
        </div>
        <ScoreBox score={environmentalScore}>
            <Typography>
              {environmentalScore.toFixed(2)}
            </Typography>
          </ScoreBox>
      </Box>
      <Box textAlign="center">
        <h3>Social</h3>
        <div style={{ height: '150px', width: '150px' }}>
          <Doughnut data={createChartData(normalizedSocialScore)} options={options} />
        </div>
        <ScoreBox score={socialScore}>
            <Typography>
              {socialScore.toFixed(2)}
            </Typography>
          </ScoreBox>
      </Box>
      <Box textAlign="center">
        <h3>Governance</h3>
        <div style={{ height: '150px', width: '150px' }}>
          <Doughnut data={createChartData(normalizedGovernanceScore)} options={options} />
        </div>
        <ScoreBox score={governanceScore}>
            <Typography>
              {governanceScore.toFixed(2)}
            </Typography>
          </ScoreBox>
      </Box>
    </div>
  );
}

export default ESGVisualization;
