import React, { useEffect, useState, createContext } from "react";
import { getAuth, signOut } from "firebase/auth"; // Import signOut
import { firebaseConfig } from "./firebaseConfig";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const auth = getAuth(firebaseConfig);
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe; // This is a cleanup function that is called when the component unmounts
  }, []);

  const logOut = async () => {
    try {
      await signOut(getAuth(firebaseConfig));
      console.log("User signed out successfully");
      setCurrentUser(null);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };


  return (
    <AuthContext.Provider value={{ currentUser, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};
