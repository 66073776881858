import { firestore } from './firestoreConfig';
import { doc, setDoc, collection } from 'firebase/firestore';

class FirebaseService {
  async updateUserData(userId, customizationData) {
    // First, get a reference to the collection
    const usersCollection = collection(firestore, 'users');

    // Then, get a reference to the specific document in that collection
    const userDocRef = doc(usersCollection, userId);

    try {
      // Now, use setDoc to update the document
      await setDoc(userDocRef, customizationData, { merge: true });
      console.log('User customization data updated successfully');
    } catch (error) {
      console.error('Error updating user data:', error);
      throw error;
    }
  }
}

const firebaseService = new FirebaseService();
export default firebaseService;
