import { useState, useContext } from "react";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Typography from "@mui/material/Typography";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/signupimg.jpg";

import { signInWithEmailAndPassword, GoogleAuthProvider, getAuth, signInWithPopup, sendPasswordResetEmail} from "firebase/auth";
import { firebaseConfig } from "firebaseConfig";
import { AuthContext } from "auth.js";
import Socials from "layouts/authentication/components/Socials";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material'; // Import a loading indicator from MUI

function SignIn() {

  const googleProvider = new GoogleAuthProvider();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");

  const navigate = useNavigate(); // Add this if not already imported
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const checkCustomization = async () => {
      if (currentUser) {
        const db = getFirestore(firebaseConfig);
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data().hasOwnProperty('companyName')) {
          navigate('/dashboard');
        } else {
          navigate('/customization');
        }
        setLoading(false); // Set loading to false after the checks
      } else {
        setLoading(false); // Also set loading to false if there's no user
      }
    };

    checkCustomization();
  }, [currentUser, navigate]);

  if (loading) {
    // While loading, show a circular progress indicator or similar
    return <CircularProgress />;
  }

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;
    // This is not secure at all, we need to find better way to get password and email

    try {
      await signInWithEmailAndPassword(getAuth(firebaseConfig), email.value, password.value);
      console.log("Success sign in")
      // console.log( email.value)
      // console.log(password.value)


    } catch (error) {
      setError(error);
      console.log(error)
    }
  };

  
  const handleForgotPassword = async () => {
    if (!email) {
      alert("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(getAuth(firebaseConfig), email);
      alert("Password reset email sent. Check your email inbox.");
    } catch (error) {
      setError(error);
      console.error(error);
    }
};

  // TODO: Ziyad
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(getAuth(firebaseConfig), googleProvider);
      const user = result.user;
      console.log("Success sign in with google")
      // console.log(user.email)
    } catch (err) {
      console.error(err);
    }
  };

  if (currentUser) {
    return <Navigate to="/dashboard" />;
  }


  return (
    <CoverLayout
      title="Welcome back"
      description="Sign in below"
      image={curved9}
    >
      <SoftBox component="form" role="form" onSubmit={handleSignIn}>
        <SoftBox mb={2}>
        <SoftBox mb={2} onClick={signInWithGoogle} >
          <Socials />
        </SoftBox>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput type="email" placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput type="password" placeholder="Password" name="password" />
        </SoftBox>
        <SoftBox mt={1} textAlign="right">
          <SoftButton variant="text" color="info" fontWeight="medium" textGradient onClick={handleForgotPassword} style={{ color: '#5271ff' }}>
            Forgot your password?
        </SoftButton>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton type="submit" variant="gradient" fullWidth style={{ backgroundColor: '#5271ff', color: 'white' }}>
            sign in
          </SoftButton>
        </SoftBox>

        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <Typography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="#5271ff"
              fontWeight="bold"
            >
              Sign up
            </Typography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
