import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';

export const firebaseConfig = firebase.initializeApp ({
    apiKey: "AIzaSyA88rueJdWTJMpcKwFyc00mg9LSvRrQoAA",
    authDomain: "algosight-frontend.firebaseapp.com",
    databaseURL: "https://algosight-frontend-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "algosight-frontend",
    storageBucket: "algosight-frontend.appspot.com",
    messagingSenderId: "372568619041",
    appId: "1:372568619041:web:94a4d453d090fa34020b60",
    measurementId: "G-7BV05T024E"
});

