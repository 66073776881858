import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Link,
  Button,
  List,
  ListItem,
  ListItemText,
  Slider
} from '@mui/material';
import { AuthContext } from 'auth';
import { styled } from '@mui/material/styles';
import { parseISO, format } from 'date-fns';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GeopoliticalVisualization from "../GeopoliticalVisualization";
import { getFirestore, doc, updateDoc, increment, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { countryNames } from "layouts/CountryMap";
import StockChart from "layouts/GraphVisualization";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const ScoreBox = styled(Box)(({ score }) => ({
  background: getScoreColor(score),
  color: "black",
  padding: "4px 8px",
  borderRadius: "4px",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "60px", // Adjust width as needed
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  transition: "background-color 0.3s",
  marginLeft: "8px", // Space from the label
}));

const getScoreColor = (score) => {
  let red, green, blue, alpha;

  if (score > 0) {
    red = 0;
    green = 180; // Slightly brighter green
    blue = 0;
    alpha = score - score * 0.4;
  } else if (score < 0) {
    red = 245;
    green = 0;
    blue = 0;
    alpha = Math.abs(score) + score * 0.4;
  } else {
    red = 200;
    green = 200;
    blue = 200;
    alpha = 1;
  }

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

const getInvestmentTypeColor = (type) => {
  switch (type) {
    case 'equities':
      return '#00bcd4'; // cyan
    case 'commodities':
      return '#ff9800'; // orange
    case 'region':
      return '#4caf50'; // green
    case 'country':
      return '#9c27b0'; // purple
    default:
      return '#607d8b'; // default color
  }
};

const Badge = styled(Box)(({ color }) => ({
  display: 'inline-block',
  padding: '2px 4px', // Reduced padding
  borderRadius: '4px',
  backgroundColor: color,
  color: 'white',
  fontWeight: 'bold',
  fontSize: '0.8rem', // Smaller font size
  marginLeft: '12px', // Add spacing between badges
}));

const AnalysisRunCard = ({ job, onViewDetails, onRerunAnalysis }) => {
  const [geoTabValue, setGeoTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [showNewCard, setShowNewCard] = useState(false); // State to manage the visibility of the new card
  const [zoom, setZoom] = useState(0.8);

  const [jobData, setJobData] = useState(null);
  const { jobId } = useParams();

  useEffect(() => {
    const fetchJob = async () => {
      setIsLoading(true);
      const db = getFirestore();
      const jobRef = doc(db, "jobs", job.id); // use job.id from the prop
      const docSnap = await getDoc(jobRef);
  
      if (docSnap.exists()) {
        const jobData = docSnap.data();
  
        if (jobData.result && jobData.result.gsiMapping) {
          // Transform the scores in gsiMapping
          const transformedGsiMapping = Object.entries(jobData.result.gsiMapping).reduce((acc, [country, { score, statement }]) => {
            acc[country] = { score: 2 * score - 1, statement }; // Transform the score from [0, 1] to [-1, 1]
            return acc;
          }, {});
  
          // Update jobData with transformed gsiMapping scores
          jobData.result.gsiMapping = transformedGsiMapping;
        }
  
        setJobData({ id: docSnap.id, ...jobData });
      } else {
        console.log("No such document!");
      }
  
      // const recurringJobRef = doc(db, 'recurring_jobs', job.id); // use job.id from the prop
      // const recurringDocSnap = await getDoc(recurringJobRef);
  
      // if (recurringDocSnap.exists()) {
      //   setIsRecurringJob(true);
      // } else {
      //   setIsRecurringJob(false);
      // }
  
      setIsLoading(false);
    };
  
    fetchJob();
  }, [job.id]);
  
  






  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  const handleGeoTabChange = (event, newValue) => {
    setGeoTabValue(newValue);
  };

  const handleDeleteAnalysis = async () => {
    try {
      const db = getFirestore();
      const jobRef = doc(db, "jobs", job.id);
      await deleteDoc(jobRef);
      console.log(`Job ${job.id} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting analysis:", error);
      alert("An error occurred while deleting the analysis.");
    }
  };


  const handleRerunAnalysis = async () => {
    setIsLoading(true); // Optionally set loading state

    const formData = new FormData();
    formData.append("userId", currentUser.uid);
    formData.append("name", job.name);
    formData.append("type", job.type);
    formData.append("specificType", job.specific_investment);
    formData.append("is_gen_report", job.is_gen_report ? 'true' : 'false');
    formData.append("is_geopolitical", 'true'); // Explicitly set is_geopolitical to true
    formData.append("submissionTime", new Date().toISOString()); // Use current time for new submission
    formData.append("jobId", job.id);

    try {
      const response = await fetch(`https://algosight-app.com/run-analysis`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Rerun successful", data);

        // Update the job state with the new results
        if (onRerunAnalysis) onRerunAnalysis(data.results, job.id);

        // Increment the assetsAnalyzed metric
        const db = getFirestore();
        const userMetricsRef = doc(db, 'userMetrics', currentUser.uid);

        const userMetricsDoc = await getDoc(userMetricsRef);
        if (!userMetricsDoc.exists()) {
          // Initialize the document if it doesn't exist
          await setDoc(userMetricsRef, {
            logins: 0,
            analysisJobsCreated: {
              equities: 0,
              commodities: 0,
              regions: 0,
              countries: 0
            },
            industrySelections: {
              Technology: 0,
              Healthcare: 0,
              Finance: 0,
              Energy: 0,
              ConsumerGoods: 0
            },
            industriesAnalysed: {
              Technology: 0,
              Healthcare: 0,
              Finance: 0,
              Energy: 0,
              ConsumerGoods: 0
            },
            assetsAnalyzed: {
              equities: 0,
              commodities: 0,
              regions: 0,
              countries: 0
            }
          });
        }

        await updateDoc(userMetricsRef, {
          [`assetsAnalyzed.${job.type}`]: increment(1)
        });

        console.log('Updated assets analyzed metrics');
      } else {
        throw new Error("Failed to rerun analysis");
      }
    } catch (error) {
      console.error("Error rerunning analysis:", error);
      alert("An error occurred while rerunning the analysis.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderGeopoliticalVisualization = () => {
    if (
      jobData &&
      jobData.result &&
      jobData.result.gsiMapping &&
      Object.keys(jobData.result.gsiMapping).length > 0
    ) {
      const mappingData = Object.entries(jobData.result.gsiMapping).map(
        ([country, { score, statement }]) => ({
          country: country.toUpperCase(), // Ensure country codes are in correct format
          value: `${statement} (Score: ${score.toFixed(2)})`, // Display the transformed score
          color: getScoreColor(score) // Add color based on transformed score
        })
      );
  
      return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ transform: `scale(${zoom})`, transformOrigin: '15% 50%' }}>
            <Box maxWidth={'550px'} width={'100%'} >
              <GeopoliticalVisualization mappingData={mappingData} />
            </Box>
          </div>
          <Slider
            value={zoom}
            min={0.5}
            max={2}
            step={0.1}
            onChange={handleZoomChange}
            aria-labelledby="zoom-slider"
            style={{ marginTop: '16px', width: '80%' }}
            defaultValue={2}
          />
        </div>
      );
    } else {
      return (
        <Typography variant="body1">
          No geopolitical analysis has been run.
        </Typography>
      );
    }
  };
  
  const renderCountryTable = () => (
    jobData && jobData.result && jobData.result.gsiMapping && Object.keys(jobData.result.gsiMapping).length > 0 && (
      <>
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>Country Geopolitical Overview</Typography>
          <TableContainer component={Paper} style={{ maxHeight: 400, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
            <Table stickyHeader size="small">
              <TableBody>
                {Object.entries(jobData.result.gsiMapping).map(([countryCode, { score, statement }]) => (
                  <TableRow key={countryCode}>
                    <TableCell>{countryNames[countryCode] || countryCode}</TableCell>
                    <TableCell>
                      <ScoreBox score={score}>{score.toFixed(2)}</ScoreBox>
                    </TableCell>
                    <TableCell>{statement}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    )
  );
  
  



  const renderGeopoliticalTopicsTable = () => (
    job.result.geopoliticalTopics && Object.keys(job.result.geopoliticalTopics).length > 0 && (
      <>
        <Box mt={2}>
          <Typography variant="h6" gutterBottom>Geopolitical Analysis Overview</Typography>
          <TableContainer component={Paper} style={{ maxHeight: 400, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
            <Table stickyHeader size="small">
              <TableBody>
                {Object.entries(job.result.geopoliticalTopics).map(([topic, [score, description]]) => (
                  description && (
                    <TableRow key={topic}>
                      <TableCell>{topic}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Box
                            style={{
                              background: getScoreColor(score),
                              color: 'black',
                              padding: '4px 8px',
                              borderRadius: '4px',
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minWidth: '60px',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              transition: 'background-color 0.3s',
                              marginLeft: '8px',
                            }}
                          >
                            <Typography variant="body2">{score.toFixed(2)}</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>{description}</TableCell>
                    </TableRow>
                  )
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    )
  );


  const renderNewsSources = () => (
    job.result.newsSources && job.result.newsSources.length > 0 && (
      <>
        <Typography variant="h6" gutterBottom>News Sources</Typography>
        <Paper style={{ padding: '16px', marginTop: '16px' }}>
          <List>
            {job.result.newsSources.map((source, index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={source}
                  primaryTypographyProps={{
                    fontSize: '1rem', // Smaller font size
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </>
    )
  );

  const DeleteIconButton = styled(IconButton)({
    color: 'grey',
    '&:hover': {
      color: 'red',
    },
  });

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h5" gutterBottom>
              {job.name}
            </Typography>
            {job.specific_investment && (
              <Badge color="#5271ff">
                {job.specific_investment}
              </Badge>
            )}
            {job.type && (
              <Badge color={getInvestmentTypeColor(job.type)}>
                {job.type}
              </Badge>
            )}
          </Box>
          <Box>
            <Link
              component="button"
              variant="h6"
              onClick={onViewDetails}
              style={{ color: "#5271ff", marginRight: '8px', cursor: 'pointer' }}
            >
              View Details
            </Link>
            <DeleteIconButton onClick={handleDeleteAnalysis}>
              <DeleteOutlineIcon />
            </DeleteIconButton>
          </Box>
        </Box>

        <Typography variant="body2" color="textSecondary" gutterBottom>
          Last Run: {job.lastRun ? format(parseISO(job.lastRun), 'PPPpp') : 'N/A'}
        </Typography>
        <Box mt={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h6" gutterBottom>
                Geopolitical Pulse
              </Typography>
              <Tooltip title="Geopolitical Pulse provides a news sentiment score reflecting the stability and geopolitical risk associated with a country or region. Key: -1: negative, +1: positive">
                <IconButton size="small">
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Button
              variant="contained"
              onClick={handleRerunAnalysis}
              style={{ backgroundColor: "#5271ff", color: "white", marginLeft: '8px' }}
              disabled={isLoading}
            >
              {isLoading ? 'Rerunning...' : 'Rerun Analysis'}
            </Button>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="subtitle2" gutterBottom>
              Score:
            </Typography>
            <Box
              style={{
                background: getScoreColor(job.result?.gsiOverall || 0),
                color: 'black',
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '60px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s',
                marginLeft: '8px',
              }}
            >
              <Typography variant="body2">{job.result?.gsiOverall?.toFixed(2) || 'N/A'}</Typography>
            </Box>
          </Box>
          <Tabs value={geoTabValue} onChange={handleGeoTabChange} aria-label="Geopolitical analysis tabs">
            <Tab label="Geopolitical Events" />
            <Tab label="Global Visualization" />
            {/* <Tab label="Historical Impact" /> */}
          </Tabs>
          {geoTabValue === 0 && (
            <>
              {job.result.geopoliticalTopics && Object.keys(job.result.geopoliticalTopics).length > 0 && renderGeopoliticalTopicsTable()}
              {job.result.gsiMapping && Object.keys(job.result.gsiMapping).length > 0 && renderCountryTable()}
              {job.result.newsSources && job.result.newsSources.length > 0 && renderNewsSources()}
            </>
          )}

          {geoTabValue === 1 && (
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {renderGeopoliticalVisualization()}
            </Grid>
          )}
          {geoTabValue === 2 && job.input && (
            <StockChart
              showCompAnalysis={showNewCard}
              specific_investment={job.input.specific_investment}
              labels={job.result.gsiLabels}
              labelData={job.result.gsiData}
            />
          )}

        </Box>
      </CardContent>
    </Card>
  );
};

export default AnalysisRunCard;


// import React, { useState, useEffect, useContext } from 'react';
// import {
//   Card,
//   CardContent,
//   Typography,
//   Box,
//   Grid,
//   Tooltip,
//   IconButton,
//   Tabs,
//   Tab,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
//   Link,
//   Button
// } from '@mui/material';
// import { AuthContext } from 'auth';
// import { styled } from '@mui/material/styles';
// import { parseISO, format } from 'date-fns';
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import GeopoliticalVisualization from "../GeopoliticalVisualization";
// import { getFirestore, doc, updateDoc, increment, getDoc, setDoc } from "firebase/firestore";

// const getScoreColor = (score) => {
//   let red, green, blue, alpha;

//   if (score > 0) {
//     red = 0;
//     green = 180; // Slightly brighter green
//     blue = 0;
//     alpha = score - score * 0.4;
//   } else if (score < 0) {
//     red = 245;
//     green = 0;
//     blue = 0;
//     alpha = Math.abs(score) + score * 0.4;
//   } else {
//     red = 200;
//     green = 200;
//     blue = 200;
//     alpha = 1;
//   }

//   return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
// };

// const getInvestmentTypeColor = (type) => {
//   switch (type) {
//     case 'equities':
//       return '#00bcd4'; // cyan
//     case 'commodities':
//       return '#ff9800'; // orange
//     case 'region':
//       return '#4caf50'; // green
//     case 'country':
//       return '#9c27b0'; // purple
//     default:
//       return '#607d8b'; // default color
//   }
// };

// const Badge = styled(Box)(({ color }) => ({
//   display: 'inline-block',
//   padding: '2px 4px', // Reduced padding
//   borderRadius: '4px',
//   backgroundColor: color,
//   color: 'white',
//   fontWeight: 'bold',
//   fontSize: '0.8rem', // Smaller font size
//   marginLeft: '12px', // Add spacing between badges
// }));


// const AnalysisRunCard = ({ job, onViewDetails, onRerunAnalysis }) => {
//   const [geoTabValue, setGeoTabValue] = useState(0);
//   const [isLoading, setIsLoading] = useState(false);
//   const { currentUser } = useContext(AuthContext);

//   const handleGeoTabChange = (event, newValue) => {
//     setGeoTabValue(newValue);
//   };

//   const handleRerunAnalysis = async () => {
//     setIsLoading(true); // Optionally set loading state

//     const formData = new FormData();
//     formData.append("userId", currentUser.uid);
//     formData.append("name", job.name);
//     formData.append("type", job.type);
//     formData.append("specificType", job.specific_investment);
//     formData.append("is_gen_report", job.is_gen_report ? 'true' : 'false');
//     formData.append("is_geopolitical", 'true'); // Explicitly set is_geopolitical to true
//     formData.append("submissionTime", new Date().toISOString()); // Use current time for new submission
//     formData.append("jobId", job.id);

//     try {
//       const response = await fetch(`http://127.0.0.1:5000/run-analysis`, {
//         method: "POST",
//         body: formData,
//       });

//       if (response.ok) {
//         const data = await response.json();
//         console.log("Rerun successful", data);

//         // Update the job state with the new results
//         if (onRerunAnalysis) onRerunAnalysis(data.results, job.id);

//         // Increment the assetsAnalyzed metric
//         const db = getFirestore();
//         const userMetricsRef = doc(db, 'userMetrics', currentUser.uid);

//         const userMetricsDoc = await getDoc(userMetricsRef);
//         if (!userMetricsDoc.exists()) {
//           // Initialize the document if it doesn't exist
//           await setDoc(userMetricsRef, {
//             logins: 0,
//             analysisJobsCreated: {
//               equities: 0,
//               commodities: 0,
//               regions: 0,
//               countries: 0
//             },
//             industrySelections: {
//               Technology: 0,
//               Healthcare: 0,
//               Finance: 0,
//               Energy: 0,
//               ConsumerGoods: 0
//             },
//             industriesAnalysed: {
//               Technology: 0,
//               Healthcare: 0,
//               Finance: 0,
//               Energy: 0,
//               ConsumerGoods: 0
//             },
//             assetsAnalyzed: {
//               equities: 0,
//               commodities: 0,
//               regions: 0,
//               countries: 0
//             }
//           });
//         }

//         await updateDoc(userMetricsRef, {
//           [`assetsAnalyzed.${job.type}`]: increment(1)
//         });

//         console.log('Updated assets analyzed metrics');
//       } else {
//         throw new Error("Failed to rerun analysis");
//       }
//     } catch (error) {
//       console.error("Error rerunning analysis:", error);
//       alert("An error occurred while rerunning the analysis.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const renderGeopoliticalVisualization = () => {
//     if (
//       job &&
//       job.result?.gsiMapping &&
//       Object.keys(job.result.gsiMapping).length > 0
//     ) {
//       const mappingData = Object.entries(job.result.gsiMapping).map(
//         ([country, description]) => ({
//           country: country.toUpperCase(), // Ensure country codes are in correct format
//           value: description,
//         })
//       );

//       return <GeopoliticalVisualization mappingData={mappingData} />;
//     } else {
//       return (
//         <Typography variant="body1">
//           No geopolitical analysis has been run.
//         </Typography>
//       );
//     }
//   };

//   return (
//     <Card>
//       <CardContent>
//         <Box display="flex" alignItems="center" justifyContent="space-between">
//           <Box display="flex" alignItems="center">
//             <Typography variant="h5" gutterBottom>
//               {job.name}
//             </Typography>
//             {job.specific_investment && (
//               <Badge color="#5271ff">
//                 {job.specific_investment}
//               </Badge>
//             )}
//             {job.type && (
//               <Badge color={getInvestmentTypeColor(job.type)}>
//                 {job.type}
//               </Badge>
//             )}
//           </Box>
//           <Link
//             component="button"
//             variant="h6"
//             onClick={onViewDetails}
//             style={{ color: "#5271ff", marginLeft: '8px', cursor: 'pointer' }}
//           >
//             View Details
//           </Link>
//         </Box>
//         <Typography variant="body2" color="textSecondary" gutterBottom>
//           Last Run: {job.lastRun ? format(parseISO(job.lastRun), 'PPPpp') : 'N/A'}
//         </Typography>
//         <Box mt={2}>
//           <Box display="flex" alignItems="center" justifyContent="space-between">
//             <Box display="flex" alignItems="center">
//               <Typography variant="h6" gutterBottom>
//                 Geopolitical Pulse
//               </Typography>
//               <Tooltip title="Geopolitical Pulse provides a news sentiment score reflecting the stability and geopolitical risk associated with a country or region. Key: -1: negative, +1: positive">
//                 <IconButton size="small">
//                   <InfoOutlinedIcon fontSize="small" />
//                 </IconButton>
//               </Tooltip>
//             </Box>
//             <Button
//               variant="contained"
//               onClick={handleRerunAnalysis}
//               style={{ backgroundColor: "#5271ff", color: "white", marginLeft: '8px' }}
//               disabled={isLoading}
//             >
//               {isLoading ? 'Rerunning...' : 'Rerun Analysis'}
//             </Button>
//           </Box>
//           <Box display="flex" alignItems="center" mb={2}>
//             <Typography variant="subtitle2" gutterBottom>
//               Score:
//             </Typography>
//             <Box
//               style={{
//                 background: getScoreColor(job.result?.gsiOverall || 0),
//                 color: 'black',
//                 padding: '4px 8px',
//                 borderRadius: '4px',
//                 display: 'inline-flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 minWidth: '60px',
//                 boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//                 transition: 'background-color 0.3s',
//                 marginLeft: '8px',
//               }}
//             >
//               <Typography variant="body2">{job.result?.gsiOverall?.toFixed(2) || 'N/A'}</Typography>
//             </Box>
//           </Box>
//           <Tabs value={geoTabValue} onChange={handleGeoTabChange} aria-label="Geopolitical analysis tabs">
//             <Tab label="Visualization" />
//             <Tab label="Events" />
//           </Tabs>
//           {geoTabValue === 0 && (
//             <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//               {renderGeopoliticalVisualization()}
//             </Grid>
//           )}
//           {geoTabValue === 1 && (
//             <TableContainer component={Paper} style={{ maxHeight: 300, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
//               <Table stickyHeader size="small">
//                 <TableBody>
//                   {Object.entries(job.result?.gsiMapping || {}).map(([country, description]) => (
//                     <TableRow key={country}>
//                       <TableCell>{country}</TableCell>
//                       <TableCell>{description}</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           )}
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

// export default AnalysisRunCard;









// import React, { useState } from 'react';
// import {
//   Card,
//   CardContent,
//   Typography,
//   Box,
//   Grid,
//   Tooltip,
//   IconButton,
//   Tabs,
//   Tab,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableRow,
//   Link,
//   Button
// } from '@mui/material';
// import { styled } from '@mui/material/styles';  // Add this import statement
// import { parseISO, format } from 'date-fns';
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import GeopoliticalVisualization from "../GeopoliticalVisualization"; // Assuming this is the correct path

// const getScoreColor = (score) => {
//   let red, green, blue, alpha;

//   if (score > 0) {
//     red = 0;
//     green = 255;
//     blue = 0;
//     alpha = score - score * 0.4;
//   } else if (score < 0) {
//     red = 245;
//     green = 0;
//     blue = 0;
//     alpha = Math.abs(score) + score * 0.4;
//   } else {
//     red = 200;
//     green = 200;
//     blue = 200;
//     alpha = 1;
//   }

//   return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
// };

// const getInvestmentTypeColor = (type) => {
//   switch (type) {
//     case 'equities':
//       return '#00bcd4'; // cyan
//     case 'commodities':
//       return '#ff9800'; // orange
//     case 'region':
//       return '#4caf50'; // green
//     case 'country':
//       return '#9c27b0'; // purple
//     default:
//       return '#607d8b'; // default color
//   }
// };

// const Badge = styled(Box)(({ color }) => ({
//   display: 'inline-block',
//   padding: '2px 4px', // Reduced padding
//   borderRadius: '4px',
//   backgroundColor: color,
//   color: 'white',
//   fontWeight: 'bold',
//   fontSize: '0.8rem', // Smaller font size
//   marginLeft: '12px', // Add spacing between badges
// }));

// const AnalysisRunCard = ({ job, onViewDetails, onRerunAnalysis }) => {
//   const [geoTabValue, setGeoTabValue] = useState(0);

//   const handleGeoTabChange = (event, newValue) => {
//     setGeoTabValue(newValue);
//   };

//   const renderGeopoliticalVisualization = () => {
//     if (
//       job &&
//       job.result?.gsiMapping &&
//       Object.keys(job.result.gsiMapping).length > 0
//     ) {
//       const mappingData = Object.entries(job.result.gsiMapping).map(
//         ([country, description]) => ({
//           country: country.toUpperCase(), // Ensure country codes are in correct format
//           value: description,
//         })
//       );

//       return <GeopoliticalVisualization mappingData={mappingData} />;
//     } else {
//       return (
//         <Typography variant="body1">
//           No geopolitical analysis has been run.
//         </Typography>
//       );
//     }
//   };

//   return (
//     <Card>
//       <CardContent>
//         <Box display="flex" alignItems="center" justifyContent="space-between">
//           <Box display="flex" alignItems="center">
//             <Typography variant="h5" gutterBottom>
//               {job.name}
//             </Typography>
//             {job.specific_investment && (
//               <Badge color="#5271ff">
//                 {job.specific_investment}
//               </Badge>
//             )}
//             {job.type && (
//               <Badge color={getInvestmentTypeColor(job.type)}>
//                 {job.type}
//               </Badge>
//             )}
//           </Box>
//           <Link
//             component="button"
//             variant="h6"
//             onClick={onViewDetails}
//             style={{ color: "#5271ff", marginLeft: '8px', cursor: 'pointer' }}
//           >
//             View Details
//           </Link>
//         </Box>
//         {/* <Typography variant="body2" color="textSecondary">
//           Submitted At: {job.processed_at ? format(parseISO(job.processed_at), 'PPPpp') : 'N/A'}
//         </Typography> */}
//         <Typography variant="body2" color="textSecondary" gutterBottom>
//           Last Run: {job.lastRun ? format(parseISO(job.lastRun), 'PPPpp') : 'N/A'}
//         </Typography>
//         <Box mt={2}>
//           <Box display="flex" alignItems="center" justifyContent="space-between">
//             <Box display="flex" alignItems="center">
//               <Typography variant="h6" gutterBottom>
//                 Geopolitical Pulse
//               </Typography>
//               <Tooltip title="Geopolitical Pulse provides a news sentiment score reflecting the stability and geopolitical risk associated with a country or region. Key: -1: negative, +1: positive">
//                 <IconButton size="small">
//                   <InfoOutlinedIcon fontSize="small" />
//                 </IconButton>
//               </Tooltip>
//             </Box>
//             <Button
//               variant="contained"
//               onClick={onRerunAnalysis}
//               style={{ backgroundColor: "#5271ff", color: "white", marginLeft: '8px' }}
//             >
//               Rerun Analysis
//             </Button>
//           </Box>
//           <Box display="flex" alignItems="center" mb={2}>
//             <Typography variant="subtitle2" gutterBottom>
//               Score:
//             </Typography>
//             <Box
//               style={{
//                 background: getScoreColor(job.result?.gsiOverall),
//                 color: 'black',
//                 padding: '4px 8px',
//                 borderRadius: '4px',
//                 display: 'inline-flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 minWidth: '60px',
//                 boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//                 transition: 'background-color 0.3s',
//                 marginLeft: '8px',
//               }}
//             >
//               <Typography variant="body2">{job.result?.gsiOverall.toFixed(2)}</Typography>
//             </Box>
//           </Box>
//           <Tabs value={geoTabValue} onChange={handleGeoTabChange} aria-label="Geopolitical analysis tabs">
//             <Tab label="Visualization" />
//             <Tab label="Events" />
//           </Tabs>
//           {geoTabValue === 0 && (
//             <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//               {renderGeopoliticalVisualization()}
//             </Grid>
//           )}
//           {geoTabValue === 1 && (
//             <TableContainer component={Paper} style={{ maxHeight: 300, overflow: "auto", marginTop: '16px', marginBottom: '16px' }}>
//               <Table stickyHeader size="small">
//                 <TableBody>
//                   {Object.entries(job.result.gsiMapping).map(([country, description]) => (
//                     <TableRow key={country}>
//                       <TableCell>{country}</TableCell>
//                       <TableCell>{description}</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           )}
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

// export default AnalysisRunCard;
